import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'src/uikit/Textarea';
import styles from './PortfolioDscr.module.scss';

const PortfolioDscr = ({ heading, defaultValue, hint, onChange, hasError }) => (
  <div className={styles.wrapper}>
    <h3 className={styles.heading}>{heading}</h3>

    <div className={styles.body}>
      <div className={styles.inputWrapper}>
        <Textarea
          rows="5"
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder="Portfeļa apraksts"
          error={hasError}
        />
      </div>

      <div className={styles.hintWrapper}>{hint}</div>
    </div>
  </div>
);

export default PortfolioDscr;

PortfolioDscr.propTypes = {
  heading: PropTypes.string,
  defaultValue: PropTypes.string,
  hint: PropTypes.node,
  onChange: PropTypes.func,
  hasError: PropTypes.string,
};

PortfolioDscr.defaultProps = {
  heading: '',
  defaultValue: '',
  hint: null,
  onChange: null,
  hasError: '',
};
