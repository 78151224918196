import React from 'react';
import PropTypes from 'prop-types';
import styles from './StepLabel.module.scss';

const StepLabel = ({ step, total }) => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      <em>{step}</em>
      <small> / {total}</small>
    </div>
  </div>
);

export default StepLabel;

StepLabel.propTypes = {
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

StepLabel.defaultProps = {
  step: 1,
  total: 1,
};
