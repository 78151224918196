import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthRoute = ({ component: Component }) => {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.teamReducer);
  const from = location.state?.from?.pathname || '/tool/1';
  const search = location.state?.from?.search || '';
  const to = {
    pathname: from === '/signout' ? '/tool/1' : from,
    search,
  };

  return isAuthenticated ? <Navigate to={to} /> : <Component />;
};

AuthRoute.propTypes = {
  component: PropTypes.elementType,
};

AuthRoute.defaultProps = {
  component: null,
};

export default AuthRoute;
