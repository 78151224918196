import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { uiCloseModal } from 'src/store/actions/uiActions';
import styles from './Modal.module.scss';

const Modal = ({ children }) => {
  const dispatch = useDispatch();
  const modalRef = useRef();

  const handleClose = (e) => {
    if (!modalRef.current.contains(e.target)) dispatch(uiCloseModal());
  };

  return (
    <div className={styles.modal}>
      <div className={styles.backdrop} />

      <div className={styles.wrapper}>
        <div
          className={styles.container}
          role="button"
          tabIndex={0}
          onClick={handleClose}
        >
          <div ref={modalRef} className={styles.modal}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.node,
};

Modal.defaultProps = {
  children: null,
};
