import { useEffect } from 'react';

export const usePageTitle = (title) => {
  useEffect(() => {
    const oldTitle = document.title;

    if (title && title !== oldTitle) document.title = title;

    return () => {
      document.title = oldTitle;
    };
  }, [title]);
};
