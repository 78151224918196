import { UI_SHOW_MODAL, UI_CLOSE_MODAL } from './actionTypes';

export const uiShowModal = (modal) => (dispatch) =>
  dispatch({
    type: UI_SHOW_MODAL,
    payload: modal,
  });

export const uiCloseModal = () => (dispatch) =>
  dispatch({
    type: UI_CLOSE_MODAL,
  });
