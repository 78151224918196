import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getModal } from 'src/modals';

const Modals = () => {
  const { modal } = useSelector((state) => state.uiReducer);

  return modal ? (
    <Modal key={modal.modalId} component={getModal(modal.type)} modal={modal} />
  ) : null;
};

export default Modals;

const Modal = ({ component: Component, ...rest }) =>
  Component ? <Component {...rest} /> : null;

Modal.propTypes = {
  component: PropTypes.elementType,
};

Modal.defaultProps = {
  component: null,
};
