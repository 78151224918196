export const stockList = [
  {
    title: 'Amber Grid',
    country: 'VLN',
    field: 'Enerģētika',
  },
  {
    title: 'Apranga',
    country: 'VLN',
    field: 'Patēriņa pamatpakalpojumi',
  },
  {
    title: 'AUGA group',
    country: 'VLN',
    field: 'Patēriņa pamatpreces',
  },
  {
    title: 'Amber Latvijas balzams',
    country: 'RIG',
    field: 'Patēriņa pamatpreces',
  },
  {
    title: 'Bercman Technologies',
    country: 'TLN',
    field: 'Rūpniecība',
  },
  {
    title: 'DelfinGroup',
    country: 'RIG',
    field: 'Finanses',
  },
  {
    title: 'Ekspress Grupp',
    country: 'TLN',
    field: 'Patēriņa pamatpakalpojumi',
  },
  {
    title: 'Enefit Green',
    country: 'TLN',
    field: 'Labiekārtojums',
  },
  {
    title: 'ELMO Rent',
    country: 'TLN',
    field: 'Patēriņa pamatpakalpojumi',
  },
  {
    title: 'Grigeo',
    country: 'VLN',
    field: 'Pamatmateriāli',
  },
  {
    title: 'Latvijas Gāze',
    country: 'RIG',
    field: 'Labiekārtojums',
  },
  {
    title: 'Hagen Bikes',
    country: 'TLN',
    field: 'Patēriņa pamatpakalpojumi',
  },
  {
    title: 'Hepsor',
    country: 'TLN',
    field: 'Nekustamie īpašumi',
  },
  {
    title: 'INDEXO',
    country: 'RIG',
    field: 'Finanses',
  },
  {
    title: 'Ignitis grupė',
    country: 'VLN',
    field: 'Labiekārtojums',
  },
  {
    title: 'Klaipėdos nafta',
    country: 'VLN',
    field: 'Rūpniecība',
  },
  {
    title: 'LHV Group',
    country: 'TLN',
    field: 'Finanses',
  },
  {
    title: 'TextMagic',
    country: 'TLN',
    field: 'Telekomunikācijas',
  },
  {
    title: 'MADARA Cosmetics',
    country: 'RIG',
    field: 'Patēriņa pamatpakalpojumi',
  },
  {
    title: 'J.Molner',
    country: 'TLN',
    field: 'Veselības aprūpe',
  },
  {
    title: 'Merko Ehitus',
    country: 'TLN',
    field: 'Rūpniecība',
  },
  {
    title: 'Novaturas',
    country: 'VLN',
    field: 'Patēriņa pamatpakalpojumi',
  },
  {
    title: 'Punktid Technologies',
    country: 'TLN',
    field: 'Tehnoloģijas',
  },
  {
    title: 'Rīgas kuģu būvētava',
    country: 'RIG',
    field: 'Rūpniecība',
  },
  {
    title: 'Šiaulių bankas',
    country: 'VLN',
    field: 'Finanses',
  },
  {
    title: 'SAF Tehnika',
    country: 'RIG',
    field: 'Telekomunikācijas',
  },
  {
    title: 'Tallink Grupp',
    country: 'TLN',
    field: 'Patēriņa pamatpakalpojumi',
  },
  {
    title: 'Telia Lietuva',
    country: 'VLN',
    field: 'Telekomunikācijas',
  },
  {
    title: 'Tallinna Sadam',
    country: 'TLN',
    field: 'Rūpniecība',
  },
  {
    title: 'VIRŠI-A',
    country: 'RIG',
    field: 'Patēriņa pamatpakalpojumi',
  },
];
