import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { getPreviewList } from 'src/store/actions/teamActions';
import Layout from 'src/layouts/ToolLayout';
import PreviewTable from 'src/uikit/PreviewTable';

const ListPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [list, setList] = useState({});
  const [page, setPage] = useState(searchParams.get('page') || 1);

  const handlePage = (p) => {
    dispatch(getPreviewList({ page: p }, setList));

    setSearchParams({ page: p });

    setPage(p);
  };

  useEffect(() => {
    dispatch(getPreviewList({ page }, setList));
  }, []);

  return (
    <Layout>
      <div className="container">
        <PreviewTable>
          <colgroup>
            <col width="40" />
            <col />
            <col width="140" />
            <col width="80" />
          </colgroup>

          <thead>
            <tr>
              <th>ID</th>
              <th>Lietotājvārds, Skola</th>
              <th>Izveidošanas datums</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {list.data?.map((team) => (
              <tr key={team.id}>
                <td>{team.id}</td>
                <td>
                  {team.username}
                  <div className="mt-6">{team.school}</div>
                </td>
                <td>{moment(team.created_at).format('DD.MM.YYYY.')}</td>
                <td align="right">
                  <Link to={`/preview/${team.uuid}`} target="_blank">
                    Skatīt
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </PreviewTable>

        {list && list.meta && (
          <div className="mt-24">
            Lapa:{' '}
            <select
              defaultValue={page}
              onChange={(e) => handlePage(e.target.value)}
            >
              {Array.from(Array(list.meta.last_page).keys()).map((p) => (
                <option key={p} value={p + 1}>
                  {p + 1}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ListPage;
