import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPortfolio } from 'src/store/actions/teamActions';
import useForm from 'src/hooks/useForm';
import Layout, {
  ToolHeading,
  ToolIntro,
  ToolFootnote,
  ToolWarning,
  ToolFooter,
} from 'src/layouts/ToolLayout';
import StepLabel from 'src/uikit/StepLabel';
import { Button } from 'src/uikit/Button';
import ButtonGroup from 'src/uikit/ButtonGroup';
import StockList from 'src/uikit/StockList';
import { stockList } from 'src/data/stockList';

const StockSelectPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { team, errors } = useSelector((state) => state.teamReducer);
  const { values, setValues, handleStateChange } = useForm({
    shares: [],
  });
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(
      createPortfolio(formData, () => {
        navigate('/tool/2');
      }),
    );
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(() => {
    if (!team || !team.id) return;

    setValues({ shares: team.portfolio });

    if (team.step === 5) navigate('/overview');
  }, [team]);

  return (
    <Layout>
      <form onSubmit={handleSubmit} noValidate>
        <StepLabel step={1} total={4} />

        <ToolHeading heading="Izvēlies akcijas no saraksta savam portfelim" />

        <ToolIntro>
          <p>
            Atzīmējiet <b>2-10</b> akcijas, kuras vēlaties iekļaut jūsu
            portfelī. Informāciju par šīm akcijām meklējiet biržas mājas lapā:{' '}
            <a
              href="https://nasdaqbaltic.com/statistics/lv/shares"
              target="_blank"
              rel="noreferrer"
            >
              https://nasdaqbaltic.com/statistics/lv/shares
            </a>
          </p>
        </ToolIntro>

        {team && (
          <StockList
            list={stockList}
            defaultValue={team.portfolio || []}
            onChange={(val) => handleStateChange('shares', val)}
          />
        )}

        <ToolFootnote>
          <p>
            Portfelī iekļautas <em>{values.shares?.length || 0}</em> uzņēmumu
            akcijas
          </p>
        </ToolFootnote>

        {inputErrors?.shares &&
          ['REQUIRED', 'MIN_LENGTH', 'MAX_LENGTH'].includes(
            inputErrors.shares[0],
          ) && (
            <ToolWarning>
              <p>Nepieciešams atzīmēt vismaz 2 - 10 akcijas</p>
            </ToolWarning>
          )}

        <ToolFooter>
          <ButtonGroup>
            <Button
              type="submit"
              variant="primary"
              label="Tālāk"
              isLoading={isSubmitting}
            />
          </ButtonGroup>
        </ToolFooter>
      </form>
    </Layout>
  );
};

export default StockSelectPage;
