import TeamService from 'src/services/teamService';
import {
  TEAM_UPDATE_PORTFOLIO,
  TEAM_SET_ERRORS,
  TEAM_RESET_ERRORS,
} from './actionTypes';

export const createPortfolio = (data, onSuccess, onFailure) => (dispatch) =>
  TeamService.createPortfolio(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return dispatch({
        type: TEAM_UPDATE_PORTFOLIO,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (onFailure) onFailure(err.response);

      return dispatch({
        type: TEAM_SET_ERRORS,
        payload: err.response,
      });
    });

export const storeShares = (data, onSuccess, onFailure) => (dispatch) =>
  TeamService.storeShares(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return dispatch({
        type: TEAM_UPDATE_PORTFOLIO,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (onFailure) onFailure(err.response);

      return dispatch({
        type: TEAM_SET_ERRORS,
        payload: err.response,
      });
    });

export const storePrices = (data, onSuccess, onFailure) => (dispatch) =>
  TeamService.storePrices(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return dispatch({
        type: TEAM_UPDATE_PORTFOLIO,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (onFailure) onFailure(err.response);

      return dispatch({
        type: TEAM_SET_ERRORS,
        payload: err.response,
      });
    });

export const storeDscr = (data, onSuccess, onFailure) => (dispatch) =>
  TeamService.storeDscr(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return dispatch({
        type: TEAM_UPDATE_PORTFOLIO,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (onFailure) onFailure(err.response);

      return dispatch({
        type: TEAM_SET_ERRORS,
        payload: err.response,
      });
    });

export const updatePrices = (data, onSuccess, onFailure) => (dispatch) =>
  TeamService.updatePrices(data)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return dispatch({
        type: TEAM_UPDATE_PORTFOLIO,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (onFailure) onFailure(err.response);

      return dispatch({
        type: TEAM_SET_ERRORS,
        payload: err.response,
      });
    });

export const getPreview = (id, onSuccess, onFailure) => () =>
  TeamService.getPreview(id)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return true;
    })
    .catch((err) => {
      if (onFailure) onFailure(err.response);

      return false;
    });

export const getPreviewList = (params, onSuccess, onFailure) => () =>
  TeamService.getPreviewList(params)
    .then((res) => {
      if (onSuccess) onSuccess(res.data);

      return true;
    })
    .catch((err) => {
      if (onFailure) onFailure(err.response);

      return false;
    });

export const resetErrors = () => (dispatch) =>
  dispatch({
    type: TEAM_RESET_ERRORS,
  });
