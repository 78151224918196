import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { storeShares } from 'src/store/actions/teamActions';
import useForm from 'src/hooks/useForm';
import Layout, {
  ToolHeading,
  ToolIntro,
  ToolFootnote,
  ToolWarning,
  ToolFooter,
} from 'src/layouts/ToolLayout';
import StepLabel from 'src/uikit/StepLabel';
import { Button, ButtonLink } from 'src/uikit/Button';
import ButtonGroup from 'src/uikit/ButtonGroup';
import StockTable from 'src/uikit/StockTable';
import StockTableInput from 'src/uikit/StockTableInput';

const StockSharePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { team, errors } = useSelector((state) => state.teamReducer);
  const { values, setValues, handleStateChange } = useForm({
    shares: [],
  });
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sum, setSum] = useState(0);

  const getShareSum = (portfolio) =>
    portfolio.reduce((acc, item) => {
      if (!Number.isNaN(parseInt(item.share, 10)))
        acc += parseInt(item.share || 0, 10);

      return acc;
    }, 0);

  const handleChangeShare = (title, val) => {
    const nextShares = [...values.shares];

    const share = nextShares.find((item) => item.title === title);

    share['share'] = val;

    handleStateChange('shares', nextShares);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(storeShares(formData, () => navigate('/tool/3')));
  };

  useEffect(() => {
    setSum(getShareSum(values.shares));
  }, [values]);

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(() => {
    if (!team || !team.id) return;

    setValues({ shares: team.portfolio });

    if (team.step === 5) navigate('/overview');
  }, [team]);

  return (
    <Layout>
      <form onSubmit={handleSubmit} noValidate>
        <StepLabel step={2} total={4} />

        <ToolHeading heading="Cik daudz no sava portfeļa izvietosiet šajās akcijās (procentos)?" />

        <ToolIntro>
          <p>
            Atcerieties - jūsu portfeļa kopējā vērtība ir 100 procenti, līdz ar
            to jūsu uzdevums ir norādīt, cik daudz no šī portfeļa jūs
            izvietosiet katrā no akcijām, lai kopējā vērtība portfelim būtu 100.
          </p>
        </ToolIntro>

        <StockTable>
          <colgroup>
            <col width="154" />
            <col width="184" />
          </colgroup>

          <thead>
            <tr>
              <th />
              <th>Īpatsvars portfelī, %</th>
            </tr>
          </thead>

          <tbody>
            {team?.portfolio.map((stock, i) => (
              <tr key={stock.title}>
                <td>{stock.title}</td>
                <td data-label="Īpatsvars portfelī, %">
                  <StockTableInput
                    defaultValue={stock.share || undefined}
                    onChange={(e) =>
                      handleChangeShare(stock.title, e.target.value)
                    }
                    hasError={inputErrors && `shares.${i}.share` in inputErrors}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </StockTable>

        <ToolFootnote>
          <p>
            Izvietots <em>{Math.round(sum)}</em> no 100
          </p>
        </ToolFootnote>

        {sum > 100 && (
          <ToolWarning>
            <p>Akciju summa pārsniedz 100!</p>
          </ToolWarning>
        )}

        {sum < 100 && inputErrors && !('sum' in inputErrors) && (
          <ToolWarning>
            <p>Lūdzu, ievadi visus datus!</p>
          </ToolWarning>
        )}

        {inputErrors && 'sum' in inputErrors && sum < 100 && (
          <ToolWarning>
            <p>
              Vēl nepieciešams akcijās izvietot {parseInt(100 - sum, 10)}% no
              kopējā portfeļa!
            </p>
          </ToolWarning>
        )}

        <ToolFooter>
          <ButtonGroup>
            <ButtonLink to="/tool/1" variant="secondary" label="Atpakaļ" />
            <Button
              type="submit"
              variant="primary"
              label="Tālāk"
              isLoading={isSubmitting}
            />
          </ButtonGroup>
        </ToolFooter>
      </form>
    </Layout>
  );
};

export default StockSharePage;
