import React from 'react';
import PropTypes from 'prop-types';
import styles from './ChartLayout.module.scss';

export const ChartLayoutCard = ({ children }) => (
  <div className={styles.card}>{children}</div>
);

ChartLayoutCard.propTypes = {
  children: PropTypes.node,
};

ChartLayoutCard.defaultProps = {
  children: null,
};
