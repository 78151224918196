import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { storeDscr } from 'src/store/actions/teamActions';
import useForm from 'src/hooks/useForm';
import Layout, {
  ToolHeading,
  ToolIntro,
  ToolWarning,
  ToolFooter,
} from 'src/layouts/ToolLayout';
import StepLabel from 'src/uikit/StepLabel';
import { Button, ButtonLink } from 'src/uikit/Button';
import ButtonGroup from 'src/uikit/ButtonGroup';
import {
  ChartLayout,
  ChartLayoutSection,
  ChartLayoutCard,
} from 'src/layouts/ChartLayout';
import PortfolioDscr from 'src/uikit/PortfolioDscr';
import StockShareChart from 'src/components/StockShareChart';
import StockRegionChart from 'src/components/StockRegionChart';
import StockFieldsChart from 'src/components/StockFieldsChart';

const StockOverviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { team, errors } = useSelector((state) => state.teamReducer);
  const { values, setValues, handleStateChange, getErrorMsg } = useForm({
    dscr: '',
  });
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(storeDscr(formData, () => navigate('/final')));
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(() => {
    if (!team || !team.id) return;

    setValues({ dscr: team.dscr });

    if (team.step === 5) navigate('/final');
  }, [team]);

  return (
    <Layout>
      <form onSubmit={handleSubmit} noValidate>
        <StepLabel step={4} total={4} />

        <ToolHeading heading="Portfeļa pārskats" />

        <ToolIntro>
          <p>
            Komandas portfeļa aprakstā sadaļā zemāk norādiet, kādēļ savā
            portfelī izvēlējāties iekļaut tieši šos uzņēmumus.
          </p>
        </ToolIntro>

        <ChartLayout>
          <ChartLayoutSection size="lg">
            <ChartLayoutCard>
              <StockShareChart portfolio={team?.portfolio || []} />
            </ChartLayoutCard>
          </ChartLayoutSection>

          <ChartLayoutSection size="sm">
            <ChartLayoutCard>
              <StockRegionChart portfolio={team?.portfolio || []} />
            </ChartLayoutCard>
          </ChartLayoutSection>

          <ChartLayoutSection size="md">
            <ChartLayoutCard>
              <StockFieldsChart portfolio={team?.portfolio || []} />
            </ChartLayoutCard>
          </ChartLayoutSection>
        </ChartLayout>

        <PortfolioDscr
          defaultValue={team?.dscr || ''}
          onChange={(e) => handleStateChange('dscr', e.target.value)}
          heading="Portfeļa apraksts"
          hint={
            <>
              <h4>Portfeļa aprakstā norādiet:</h4>

              <ol>
                <li>
                  Kādēļ savā portfelī izvēlējāties iekļaut konkrētās akcijas?
                </li>
                <li>
                  Pēc kādiem kritērijiem izvēlējāties sadalīt portfelī esošo
                  akciju īpatsvaru, kāpēc?
                </li>
                <li>Kādas nozares dominē, kāpēc? Kādi ir šīs nozares riski?</li>
                <li>
                  Kā vērtējat reģionālo sadalījumu? Vai tam akciju izvēlē ir
                  kāda nozīme?
                </li>
              </ol>
            </>
          }
          hasError={getErrorMsg(inputErrors, 'dscr')}
        />

        {inputErrors && 'dscr' in inputErrors && (
          <ToolWarning>
            <p>Lūdzu, ievadi visus datus!</p>
          </ToolWarning>
        )}

        <ToolFooter>
          <ButtonGroup>
            <ButtonLink to="/tool/3" variant="secondary" label="Atpakaļ" />
            <Button
              type="submit"
              variant="primary"
              label="Izveidot portfeli"
              isLoading={isSubmitting}
            />
          </ButtonGroup>
        </ToolFooter>
      </form>
    </Layout>
  );
};

export default StockOverviewPage;
