import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiShowModal } from 'src/store/actions/uiActions';
import Layout, { ToolHeading, ToolFooter } from 'src/layouts/ToolLayout';
import ToolRow from 'src/uikit/ToolRow';
import { Button, ButtonLink } from 'src/uikit/Button';
import {
  ChartLayout,
  ChartLayoutSection,
  ChartLayoutCard,
} from 'src/layouts/ChartLayout';
import StockShareChart from 'src/components/StockShareChart';
import StockRegionChart from 'src/components/StockRegionChart';
import StockFieldsChart from 'src/components/StockFieldsChart';
import ButtonGroup from 'src/uikit/ButtonGroup';

const OverviewPage = () => {
  const dispatch = useDispatch();
  const { team } = useSelector((state) => state.teamReducer);

  const handleShareModal = () =>
    dispatch(
      uiShowModal({
        type: 'SHARE_MODAL',
      }),
    );

  return (
    <Layout>
      <ToolHeading heading="Portfeļa pārskats" />

      <ToolRow>
        <ButtonGroup>
          <ButtonLink to="/update" variant="primary" label="Aktualizēt cenas" />
          <Button
            variant="secondary"
            label="Kopīgot"
            onClick={handleShareModal}
          />
        </ButtonGroup>
      </ToolRow>

      <ChartLayout>
        <ChartLayoutSection size="lg">
          <ChartLayoutCard>
            <StockShareChart portfolio={team?.portfolio || []} />
          </ChartLayoutCard>
        </ChartLayoutSection>

        <ChartLayoutSection size="sm">
          <ChartLayoutCard>
            <StockRegionChart portfolio={team?.portfolio || []} />
          </ChartLayoutCard>
        </ChartLayoutSection>

        <ChartLayoutSection size="md">
          <ChartLayoutCard>
            <StockFieldsChart portfolio={team?.portfolio || []} />
          </ChartLayoutCard>
        </ChartLayoutSection>
      </ChartLayout>

      <ToolFooter>
        <ButtonGroup>
          <ButtonLink to="/update" variant="primary" label="Aktualizēt cenas" />

          <Button
            variant="secondary"
            label="Kopīgot"
            onClick={handleShareModal}
          />
        </ButtonGroup>
      </ToolFooter>
    </Layout>
  );
};

export default OverviewPage;
