import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './TextInput.module.scss';

const TextInput = ({
  id,
  name,
  label,
  required,
  type,
  value,
  defaultValue,
  onChange,
  placeholder,
  inputProps,
  error,
  datalistId,
  datalist,
  readOnly,
}) => (
  <div className={cx(styles.wrapper, error && styles.hasError)}>
    {label && (
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    )}

    <input
      type={type}
      id={id}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      className={cx(styles.input, datalistId && styles.hasDatalist)}
      list={datalistId}
      readOnly={readOnly}
      {...inputProps}
    />

    {error && <span className={styles.error}>{error}</span>}

    {datalist && (
      <datalist id={datalistId}>
        {datalist.sort().map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </datalist>
    )}
  </div>
);

export default TextInput;

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputProps: PropTypes.any,
  error: PropTypes.string,
  datalistId: PropTypes.string,
  datalist: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
};

TextInput.defaultProps = {
  id: '',
  name: '',
  label: '',
  required: false,
  type: 'text',
  value: undefined,
  defaultValue: undefined,
  onChange: null,
  placeholder: '',
  inputProps: null,
  error: '',
  datalistId: '',
  datalist: [],
  readOnly: false,
};
