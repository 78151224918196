import { useState } from 'react';

const errorMsg = (err) => {
  switch (err) {
    case 'REQUIRED':
      return 'Šis lauks ir obligāts';

    case 'UNIQUE':
      return 'Šis lietotājvārds jau tiek lietots';

    case 'BAD_CREDENTIALS':
      return 'Nepareizs lietotājvārds';

    case 'LONG':
      return 'Vērtība ir pārāk gara';

    case 'FORMAT':
      return 'Nepareizs formāts';

    default:
      return '';
  }
};

const getError = (err) => errorMsg(err[0]);

const useForm = (initialFieldValues) => {
  const [values, setValues] = useState(initialFieldValues);

  const handleStateChange = (key, value) => {
    setValues((initState) => ({
      ...initState,
      [key]: value,
    }));
  };

  const getErrorMsg = (errorsObj, key) =>
    errorsObj && key in errorsObj ? getError(errorsObj[key]) : '';

  return {
    values,
    setValues,
    handleStateChange,
    getErrorMsg,
  };
};

export default useForm;
