import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToolLayout.module.scss';

export const ToolIntro = ({ children }) => (
  <div className={styles.intro}>{children}</div>
);

ToolIntro.propTypes = {
  children: PropTypes.node,
};

ToolIntro.defaultProps = {
  children: null,
};
