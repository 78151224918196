import React from 'react';
import PropTypes from 'prop-types';
import styles from './StockTable.module.scss';

const StockTable = ({ children }) => (
  <div className={styles.wrapper}>
    <table className={styles.table}>{children}</table>
  </div>
);

export default StockTable;

StockTable.propTypes = {
  children: PropTypes.node,
};

StockTable.defaultProps = {
  children: null,
};
