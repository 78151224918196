import { combineReducers } from 'redux';
import teamReducer from './teamReducer';
import uiReducer from './uiReducer';

const reducers = combineReducers({
  teamReducer,
  uiReducer,
});

export default reducers;
