import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Button.module.scss';

export const Button = ({ type, label, variant, onClick, isLoading }) => (
  <button
    type={type}
    className={cx(styles.btn, styles[variant], isLoading && styles.isLoading)}
    onClick={onClick}
  >
    {label}
  </button>
);

Button.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'inline-link']),
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  label: '',
  variant: 'primary',
  onClick: null,
  isLoading: false,
};
