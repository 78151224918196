import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

const colors = [
  '#FB4F00',
  '#FDC92A',
  '#990066',
  '#D28AC5',
  '#31A3AE',
  '#8ACDC3',
  '#FF9100',
  '#C5569A',
  '#FFECFF',
  '#FFF1CD',
];

const fields = [
  'Enerģētika',
  'Patēriņa pamatpakalpojumi',
  'Patēriņa pamatpreces',
  'Rūpniecība',
  'Finanses',
  'Labiekārtojums',
  'Pamatmateriāli',
  'Nekustamie īpašumi',
  'Telekomunikācijas',
  'Veselības aprūpe',
  'Tehnoloģijas',
];

const StockFieldsChart = ({ portfolio }) => (
  <Chart
    chartType="BarChart"
    data={[
      [
        'Field',
        '',
        { role: 'style' },
        { role: 'tooltip', type: 'string', p: { html: true } },
      ],

      ...fields.reduce((acc, field, i) => {
        const count = portfolio.filter((stock) => stock.field === field).length;

        if (count) {
          const val = count / portfolio.length;

          acc.push([
            field,
            val,
            colors[i],
            `${field} \n ${count} (${Math.round(val * 100)}%)`,
          ]);
        }

        return acc;
      }, []),
    ]}
    options={{
      title: 'Nozaru sadalījums',
      legend: { position: 'none' },
      titleTextStyle: {
        fontName: 'SwedbankHeadline-Black',
        fontSize: 20,
        color: '#512B2B',
      },
      vAxis: {
        textStyle: {
          fontSize: 10,
          bold: true,
        },
      },
      hAxis: {
        format: '#%',
        baseline: 0,
        baselineColor: '#512B2B',
        gridlines: {
          color: '#e5dcd7',
        },
      },
      chartArea: {
        right: '15%',
        left: '30%',
      },
      width: '100%',
      height: '100%',
      bar: { groupWidth: 20 },
    }}
  />
);

export default StockFieldsChart;

StockFieldsChart.propTypes = {
  portfolio: PropTypes.arrayOf(PropTypes.object),
};

StockFieldsChart.defaultProps = {
  portfolio: [],
};
