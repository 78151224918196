import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signupTeam, resetAuthErrors } from 'src/store/actions/authActions';
import useForm from 'src/hooks/useForm';
import Layout from 'src/layouts/MainLayout';
import {
  SignupFormWrapper,
  SignupFormHeading,
  SignupFormRow,
  SignupFormFootnote,
  SignupFormFooter,
} from 'src/uikit/SignupForm';
import { FormRow } from 'src/uikit/Form';
import { Button, ButtonLink } from 'src/uikit/Button';
import TextInput from 'src/uikit/TextInput';
import { schoolList } from 'src/data/schoolList';

const SignupPage = () => {
  const dispatch = useDispatch();
  const { errors } = useSelector((state) => state.teamReducer);
  const { values, handleStateChange, getErrorMsg } = useForm({
    username: '',
    school: '',
  });
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(signupTeam(formData));
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(
    () => () => {
      dispatch(resetAuthErrors());
    },
    [],
  );

  return (
    <Layout>
      <div className="container">
        <SignupFormWrapper>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            spellCheck="false"
          >
            <SignupFormHeading heading="Reģistrācija" />

            <SignupFormRow>
              <FormRow>
                <TextInput
                  id="username"
                  name="username"
                  type="text"
                  label="Lietotājvārds"
                  onChange={(e) =>
                    handleStateChange('username', e.target.value)
                  }
                  error={getErrorMsg(inputErrors, 'username')}
                  required
                />
              </FormRow>

              <FormRow>
                <TextInput
                  id="school"
                  name="school"
                  label="Skola"
                  datalistId="schools"
                  datalist={schoolList}
                  onChange={(e) => handleStateChange('school', e.target.value)}
                  error={getErrorMsg(inputErrors, 'school')}
                  required
                />
              </FormRow>
            </SignupFormRow>

            <SignupFormRow>
              <SignupFormFootnote>
                * Saglabājiet vai paturiet prātā savu lietotājvārdu. Tas būs
                nepieciešams, ja pēc kāda laika vēlēsieties noskaidrot, kā
                veicies izveidotajam portfelim.
              </SignupFormFootnote>
            </SignupFormRow>

            <SignupFormFooter>
              <div>
                <Button
                  type="submit"
                  label="Reģistrēties"
                  variant="primary"
                  isLoading={isSubmitting}
                />
              </div>

              <div>
                <ButtonLink to="/signin" label="Ienākt" variant="link" />
              </div>
            </SignupFormFooter>
          </form>
        </SignupFormWrapper>
      </div>
    </Layout>
  );
};

export default SignupPage;
