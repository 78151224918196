export const AUTH_SET_INITIALIZED = 'AUTH_SET_INITIALIZED';
export const AUTH_SET_TEAM = 'AUTH_SET_TEAM';
export const AUTH_SIGNIN_TEAM = 'AUTH_SIGNIN_TEAM';
export const AUTH_SIGNUP_TEAM = 'AUTH_SIGNUP_TEAM';
export const AUTH_SIGNOUT_TEAM = 'AUTH_SIGNOUT_TEAM';
export const AUTH_SET_ERRORS = 'AUTH_SET_ERRORS';
export const AUTH_RESET_ERRORS = 'AUTH_RESET_ERRORS';

export const UI_SHOW_MODAL = 'UI_SHOW_MODAL';
export const UI_CLOSE_MODAL = 'UI_CLOSE_MODAL';

export const TEAM_UPDATE_PORTFOLIO = 'TEAM_UPDATE_PORTFOLIO';
export const TEAM_SET_ERRORS = 'TEAM_SET_ERRORS';
export const TEAM_RESET_ERRORS = 'TEAM_RESET_ERRORS';
