import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiCloseModal } from 'src/store/actions/uiActions';
import { Button } from 'src/uikit/Button';
import Modal from 'src/uikit/Modal';
import TextInput from 'src/uikit/TextInput';
import styles from './ShareModal.module.scss';

const CookieModal = () => {
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();
  const { team } = useSelector((state) => state.teamReducer);

  const handleClose = () => dispatch(uiCloseModal());

  const handleCopy = () =>
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_BASE}/preview/${team.uuid}`)
      .then(() => setIsCopied(true));

  useEffect(() => {
    if (!isCopied) return;

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [isCopied]);

  if (!team) return null;

  return (
    <Modal>
      <h3 className={styles.heading}>Kopīgot portfeli</h3>

      <div>
        <TextInput
          defaultValue={`${process.env.REACT_APP_BASE}/preview/${team.uuid}`}
          readOnly
        />
      </div>

      {isCopied ? (
        <div className={styles.successMsg}>Saite veiksmīgi nokopēta!</div>
      ) : (
        <div className="mt-16">
          <Button
            type="button"
            variant="inline-link"
            label="Kopēt saiti"
            onClick={handleCopy}
          />
        </div>
      )}

      <div className={styles.footer}>
        <Button
          type="button"
          variant="primary"
          label="Aizvērt"
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
};

export default CookieModal;
