import React from 'react';
import PropTypes from 'prop-types';
import styles from './FinalMsg.module.scss';

export const FinalMsgWrapper = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

FinalMsgWrapper.propTypes = {
  children: PropTypes.node,
};

FinalMsgWrapper.defaultProps = {
  children: null,
};
