import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import styles from './StockRegionChart.module.scss';

const StockRegionChart = ({ portfolio }) => (
  <div className={styles.wrapper}>
    <div className={styles.chartTitle}>Reģionālais sadalījums</div>

    <Chart
      chartType="PieChart"
      data={[
        ['Country', 'Weight'],
        ['Latvija', portfolio.filter((item) => item.country === 'RIG').length],
        ['Igaunija', portfolio.filter((item) => item.country === 'TLN').length],
        ['Lietuva', portfolio.filter((item) => item.country === 'VLN').length],
      ]}
      options={{
        legend: {
          position: 'bottom',
          textStyle: {
            fontName: 'Roboto',
            fontSize: 11,
            bold: true,
          },
        },
        colors: ['#FF5F00', '#FDC92A', '#990066'],
        pieSliceTextStyle: {
          fontName: 'SwedbankHeadline-Black',
          fontSize: 20,
        },
        chartArea: {
          width: '90%',
          height: '90%',
        },
      }}
      width="100%"
      height="380px"
      legendToggle
    />
  </div>
);

export default StockRegionChart;

StockRegionChart.propTypes = {
  portfolio: PropTypes.arrayOf(PropTypes.object),
};

StockRegionChart.defaultProps = {
  portfolio: [],
};
