import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { isInitialized, isAuthenticated } = useSelector(
    (state) => state.teamReducer,
  );

  if (!isInitialized) return null;

  if (!isAuthenticated)
    return <Navigate to="/signin" state={{ from: location }} replace />;

  return <Component {...rest} />;
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

PrivateRoute.defaultProps = {
  component: null,
};
