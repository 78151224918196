import React from 'react';
import Layout from 'src/layouts/MainLayout';
import HeroImg from 'src/uikit/HeroImg';
import TextBlock from 'src/uikit/TextBlock';
import ButtonGroup from 'src/uikit/ButtonGroup';
import { ButtonLink } from 'src/uikit/Button';

const IndexPage = () => (
  <Layout hero={<HeroImg src="/hero.jpg" />}>
    <div className="container">
      <TextBlock>
        <h1>Kāds būs jūsu ieguldījumu portfelis?</h1>

        <p className="textOrange mb-42">
          <b>Kas jādara?</b>
        </p>

        <p className="mb-54">
          <b>
            Sadalieties grupās pa 3 vai 4 un izveidojiet savu virtuālo
            ieguldījumu portfeli, sekojot 4 secīgiem soļiem:
          </b>
        </p>

        <ol className="mb-54">
          <li>
            izvēlieties no saraksta uzņēmumu akcijas, kuras vēlaties iekļaut
            savā portfelī;
          </li>
          <li>
            norādiet, cik lielu daļu (procentos) no portfeļa vēlaties iekļaut
            izvēlētajās akcijās;
          </li>
          <li>
            ievadiet izvēlēto vērtspapīru tirgus cenu (aktuālo cenu meklējiet
            biržas mājaslapā https://nasdaqbaltic.com/statistics/lv/shares);
          </li>
          <li>
            pamatojiet, kādēļ tieši šo uzņēmumu akcijas iekļāvāt savā portfelī.
          </li>
        </ol>
        <p>
          <b>
            Pēc mēneša varat atgriezties šajā rīkā, lai noskaidrotu, kā jūsu
            portfelim veicies – vai tas ir kļuvis vērtīgāks, vai gluži pretēji,
            tā vērtība sarukusi.
          </b>
        </p>
      </TextBlock>

      <div className="mt-42">
        <ButtonGroup>
          <ButtonLink to="/signup" variant="primary" label="Reģistrēties" />
          <ButtonLink to="/signin" variant="secondary" label="Ienākt" />
        </ButtonGroup>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
