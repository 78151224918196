import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToolLayout.module.scss';

export const ToolFootnote = ({ children }) => (
  <div className={styles.footnote}>{children}</div>
);

ToolFootnote.propTypes = {
  children: PropTypes.node,
};

ToolFootnote.defaultProps = {
  children: null,
};
