import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './Button.module.scss';

export const ButtonLink = ({ label, variant, ...rest }) => (
  <Link className={cx(styles.btn, styles[variant])} {...rest}>
    {label}
  </Link>
);

ButtonLink.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'inline-link']),
};

ButtonLink.defaultProps = {
  label: '',
  variant: 'primary',
};
