import axios from 'axios';
import store from 'src/store/store';

let retry = 0;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  withCredentials: true,
  headers: {
    post: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

api.interceptors.response.use(
  (response) => response,
  async (err) => {
    const errorResponse = err.response;
    const errorStatus = errorResponse.status;
    const errorConfig = err.config;

    if (errorStatus === 401) {
      store.dispatch({
        type: 'AUTH_SIGNOUT_TEAM',
      });
    }

    if (errorStatus === 419 && !errorConfig.sent && retry < 3) {
      errorConfig.sent = true;

      retry += 1;

      await api.get('/auth/csrf-cookie');

      retry = 0;

      return axios(errorConfig);
    }

    throw err;
  },
);

export default api;

//
//
//
// api.interceptors.response.use(
//   (response) => {
//     handleAppVersion(response);
//
//     return response;
//   },
//   async (err) => {
//     const errorResponse = err.response;
//     const errorStatus = errorResponse.status;
//     const errorConfig = err.config;
//
//     if (errorStatus === 401) {
//       store.dispatch({
//         type: 'AUTH_LOGOUT_USER',
//       });
//     }
//
//     if (errorStatus === 403) {
//       store.dispatch({
//         type: 'UI_SHOW_TOAST',
//         payload: {
//           type: 'danger',
//           text: 'This action is forbidden!',
//         },
//       });
//     }
//
//     if (errorStatus === 419 && !errorConfig.sent && retry < 3) {
//       errorConfig.sent = true;
//
//       retry += 1;
//
//       await api.get('auth/csrf-cookie');
//
//       retry = 0;
//
//       return axios(errorConfig);
//     }
//
//     throw err;
//   },
// );
//
// export default api;
