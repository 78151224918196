import api from 'src/apiConfig';

const urlBase = 'teams';

const getTeam = () => api.get(`${urlBase}/me`);
const signup = (data) => api.post(`${urlBase}/signup`, data);
const signin = (data) => api.post(`${urlBase}/signin`, data);
const signout = () => api.post(`${urlBase}/signout`);
const createPortfolio = (data) => api.post(`${urlBase}/portfolio`, data);
const storeShares = (data) => api.post(`${urlBase}/shares`, data);
const storePrices = (data) => api.post(`${urlBase}/prices`, data);
const storeDscr = (data) => api.post(`${urlBase}/dscr`, data);
const updatePrices = (data) => api.post(`${urlBase}/update`, data);
const getPreview = (id) => api.get(`${urlBase}/${id}/preview`);
const getPreviewList = (params) => api.get(`${urlBase}/list`, { params });

const AuthService = {
  getTeam,
  signup,
  signin,
  signout,
  createPortfolio,
  storeShares,
  storePrices,
  storeDscr,
  updatePrices,
  getPreview,
  getPreviewList,
};

export default AuthService;
