import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Textarea.module.scss';

const Textarea = ({
  id,
  name,
  label,
  required,
  placeholder,
  value,
  defaultValue,
  onChange,
  error,
  ...inputProps
}) => (
  <div className={cx(styles.wrapper, error && styles.hasError)}>
    {label && (
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    )}

    <textarea
      id={id}
      name={name}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      required={required}
      className={styles.input}
      {...inputProps}
    />

    {error && <span className={styles.error}>{error}</span>}
  </div>
);

export default Textarea;

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

Textarea.defaultProps = {
  id: '',
  name: '',
  label: '',
  required: false,
  value: undefined,
  defaultValue: undefined,
  onChange: null,
  placeholder: '',
  error: '',
};
