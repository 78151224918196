import React from 'react';
import PropTypes from 'prop-types';
import styles from './StockList.module.scss';

const Item = ({ label, defaultSelected, onChange }) => (
  <li className={styles.item}>
    <label className={styles.itemLabel}>
      <input
        type="checkbox"
        className={styles.itemCheckbox}
        defaultChecked={defaultSelected}
        onChange={onChange}
      />
      <span className={styles.itemCheckmark} />
      <span>{label}</span>
    </label>
  </li>
);

export default Item;

Item.propTypes = {
  label: PropTypes.string,
  defaultSelected: PropTypes.bool,
  onChange: PropTypes.func,
};

Item.defaultProps = {
  label: '',
  defaultSelected: false,
  onChange: null,
};
