import React from 'react';
import PropTypes from 'prop-types';
import styles from './SignupForm.module.scss';

export const SignupFormFooter = ({ children }) => (
  <div className={styles.footer}>{children}</div>
);

SignupFormFooter.propTypes = {
  children: PropTypes.node,
};

SignupFormFooter.defaultProps = {
  children: null,
};
