export const schoolList = [
  'Aizkraukles pagasta sākumskola',
  'Pērses sākumskola',
  'Mazzalves pamatskola',
  'Daudzeses pamatskola',
  'Seces pagasta pamatskola',
  'Bebru pamatskola',
  'Neretas Jāņa Jaunsudrabiņa vidusskola',
  'Andreja Upīša Skrīveru vidusskola',
  'Jaunjelgavas vidusskola',
  'Ilmāra Gaiša Kokneses vidusskola',
  'Aizkraukles novada vidusskola',
  'Pļaviņu vidusskola',
  'Kokneses pamatskola - attīstības centrs',
  'Alūksnes pilsētas sākumskola',
  'Strautiņu pamatskola',
  'Malienas pamatskola',
  'Bejas pamatskola',
  'Pededzes pamatskola',
  'Ziemeru pamatskola',
  'Jaunannas Mūzikas un mākslas pamatskola',
  'Alekseja Grāvīša Liepnas pamatskola',
  'Alūksnes novada vidusskola',
  'Randenes pamatskola',
  'Kalupes pamatskola',
  'Biķernieku pamatskola',
  'Lāču pamatskola',
  'Naujenes pamatskola',
  'Silenes pamatskola',
  'Laucesas pamatskola',
  'Salienas vidusskola',
  'Špoģu vidusskola',
  'Zemgales vidusskola',
  'Sventes vidusskola',
  'Vaboles vidusskola',
  'Bebrenes vispārizglītojošā un profesionālā vidusskola',
  'Ilūkstes Raiņa vidusskola',
  'Medumu speciālā pamatskola',
  'Privātā pamatskola "Brīvā Austras skola"',
  'Carnikavas pamatskola',
  'Privātā vidusskola Ādažu Brīvā Valdorfa skola',
  'Ādažu vidusskola',
  'Balvu sākumskola',
  'Eglaines pamatskola',
  'Stacijas pamatskola',
  'Viduču pamatskola',
  'Žīguru pamatskola',
  'Bērzpils pamatskola',
  'Rekavas vidusskola',
  'Tilžas vidusskola',
  'Baltinavas vidusskola',
  'Rugāju vidusskola',
  'Viļakas Valsts ģimnāzija',
  'Balvu profesionālā un vispārizglītojošā vidusskola',
  'Mežotnes sākumskola',
  'Bārbeles Zēnu pamatskola "Saknes un spārni"',
  'Vecsaules pamatskola',
  'Stelpes pamatskola',
  'Griķu pamatskola',
  'Codes pamatskola',
  'Bauskas pilsētas pamatskola',
  'Bauskas novada pašvaldības vispārējās izglītības iestāde "Uzvaras pamatskola"',
  'Bauskas novada pašvaldības vispārējās izglītības iestāde "Īslīces pamatskola"',
  'Iecavas pamatskola',
  'Valles pamatskola',
  'Misas pamatskola',
  'Skaistkalnes vidusskola',
  'Vecumnieku vidusskola',
  'Iecavas vidusskola',
  'Bauskas 2. vidusskola',
  'Pilsrundāles vidusskola',
  'Bauskas Valsts ģimnāzija',
  'Pamūšas speciālā pamatskola',
  'Zālītes speciālā pamatskola',
  'Cēsu pilsētas Pastariņa sākumskola',
  'Augšlīgatnes Jaunā sākumskola',
  'Cēsu 1. pamatskola',
  'Straupes pamatskola',
  'Zaubes pamatskola',
  'Rāmuļu pamatskola',
  'Liepas pamatskola',
  'Amatas pamatskola',
  'Cēsu 2. pamatskola',
  'Skujenes pamatskola',
  'Drabešu Jaunā pamatskola',
  'Dzērbenes vispārizglītojošā un mūzikas pamatskola',
  'Stalbes pamatskola',
  'Nītaures mūzikas un mākslas pamatskola',
  'Zemes Sāls vidusskola',
  'Cēsu Jaunā vidusskola',
  'Priekuļu vidusskola',
  'Jauno Līderu vidusskola',
  'Jaunpiebalgas vidusskola',
  'Vecpiebalgas vidusskola',
  'Cēsu Pilsētas vidusskola',
  'Cēsu 2. vakara (maiņu) vidusskola',
  'Spāres pamatskola',
  'Aleksandra Bieziņa Raiskuma pamatskola',
  'Cēsu Bērzaines pamatskola-attīstības centrs',
  'Daugavpils Saskaņas pamatskola',
  'Daugavpils 11. pamatskola',
  'Daugavpils Vienības pamatskola',
  'J. Raiņa Daugavpils 6. pamatskola',
  'Daugavpils 10. vidusskola',
  'Daugavpils 3. vidusskola',
  'Daugavpils 13. vidusskola',
  'Daugavpils 15. vidusskola',
  'Daugavpils Tehnoloģiju vidusskola - licejs',
  'Daugavpils 16. vidusskola',
  'Daugavpils 17. vidusskola',
  'Daugavpils 9. vidusskola',
  'Daugavpils 12. vidusskola',
  'Daugavpils Centra vidusskola',
  'J.Pilsudska Daugavpils valsts poļu ģimnāzija',
  'Daugavpils Stropu pamatskola - attīstības centrs',
  'Māteru Jura Kazdangas pamatskola',
  'Kalvenes pamatskola',
  'Vērgales pamatskola',
  'Dzērves pamatskola',
  'Aizputes pagasta pamatskola',
  'Krotes Kronvalda Ata pamatskola',
  'Sikšņu pamatskola',
  'Rucavas pamatskola',
  'Ata Kronvalda Durbes pamatskola',
  'Kalētu Mūzikas un mākslas pamatskola',
  'Pāvilostas pamatskola',
  'Nīcas vidusskola',
  'Vaiņodes vidusskola',
  'Priekules vidusskola',
  'Aizputes vidusskola',
  'Dienvidkurzemes 2. vidusskola',
  'Zentas Mauriņas Grobiņas vidusskola',
  'Mežupes pamatskola',
  'Padures pamatskola',
  'Lejasstrazdu sākumskola',
  'Dobeles sākumskola',
  'Bikstu pamatskola',
  'Gardenes pamatskola',
  'Penkules pamatskola',
  'Mežinieku pamatskola',
  'Annas Brigaderes pamatskola',
  'Augstkalnes pamatskola',
  'Bēnes pamatskola',
  'Auces vidusskola',
  'Dobeles 1. vidusskola',
  'Bērzupes speciālā pamatskola',
  'Dobeles Amatniecības un vispārizglītojošā vidusskola',
  'Gulbenes Valdorfa pamatskola',
  'Stāķu pamatskola',
  'Rankas pamatskola',
  'Tirzas pamatskola',
  'Gulbīša pamatskola',
  'Lizuma vidusskola',
  'Lejasciema vidusskola',
  'Gulbenes novada vidusskola',
  'Sveķu pamatskola',
  'Jelgavas 4. sākumskola',
  'Lazdiņas privātā pamatskola "Punktiņš"',
  'Jelgavas Centra pamatskola',
  'Jelgavas Pārlielupes pamatskola',
  'Jelgavas 4. vidusskola',
  'Jelgavas 5. vidusskola',
  'Jelgavas Tehnoloģiju vidusskola',
  'Jelgavas Paula Bendrupa pamatskola',
  'Jelgavas pamatskola "Valdeka" - attīstības centrs',
  'Jelgavas Amatu vidusskola',
  'Teteles pamatskola',
  'Svētes pamatskola',
  'Vilces pamatskola',
  'Šķibes pamatskola',
  'Aizupes pamatskola',
  'Sesavas pamatskola',
  'Salgales pamatskola',
  'Garozas pamatskola',
  'Līvbērzes pamatskola',
  'Ozolnieku vidusskola',
  'Kalnciema vidusskola',
  'Elejas vidusskola',
  'Vircavas vidusskola',
  'Kalnciema pagasta vidusskola',
  'Staļģenes vidusskola',
  'Zaļenieku komerciālā un amatniecības vidusskola',
  'Jelgavas novada Neklātienes vidusskola',
  'Lielplatones pamatskola-atbalsta centrs',
  'Krustpils pamatskola',
  'Jēkabpils 2. vidusskola',
  'Jēkabpils 3. vidusskola',
  'Variešu sākumskola',
  'Ābeļu pamatskola',
  'Dignājas pamatskola',
  'Mežāres pamatskola',
  'Rubeņu pamatskola',
  'Sūnu pamatskola',
  'Vīpes pamatskola',
  'Biržu pamatskola',
  'Aknīstes vidusskola',
  'Brāļu Skrindu Atašienes vidusskola',
  'Salas vidusskola',
  'Viesītes vidusskola',
  'Zasas vidusskola',
  'Antūžu pamatskola',
  'Slokas pamatskola',
  'Vaivaru pamatskola',
  'Ķemeru pamatskola',
  'Jūrmalas Aspazijas pamatskola',
  'Jūrmalas pilsētas Kauguru vidusskola',
  'Pumpuru vidusskola',
  'Jūrmalas pilsētas Mežmalas vidusskola',
  'Jūrmalas pilsētas Jaundubultu vidusskola',
  'Majoru vidusskola',
  'Jūrmalas Valsts ģimnāzija',
  'Jūrmalas pilsētas pamatskola',
  'Šķaunes sākumskola',
  'Robežnieku pamatskola',
  'Andrupenes pamatskola',
  'Krāslavas gr. Plāteru v.n. Poļu pamatskola',
  'Priežmalas pamatskola',
  'Indras pamatskola',
  'Ezernieku vidusskola',
  'Dagdas vidusskola',
  'Krāslavas Varavīksnes vidusskola',
  'Krāslavas ģimnāzija',
  'Aleksandrovas pamatskola',
  'Vilgāles sākumskola',
  'Kuldīgas Kristīgā pamatskola',
  'Vārmes pamatskola',
  'Turlavas pamatskola',
  'Laidu pamatskola',
  'Nīkrāces pamatskola',
  'Ēdoles pamatskola',
  'Z.A. Meierovica Kabiles pamatskola',
  'Alsungas pamatskola',
  'Skrundas vidusskola',
  'Kuldīgas Mākslas un humanitāro zinību vidusskola',
  'Kuldīgas 2. vidusskola',
  'Kuldīgas Centra vidusskola',
  'V. Plūdoņa Kuldīgas vidusskola',
  'Viduskurzemes pamatskola-attīstības centrs',
  'Pļavniekkalna sākumskola',
  'Baldones sākumskola',
  'Privātā pamatskola "Gaismas tilts 97"',
  'Daugmales pamatskola',
  'Ķekavas vidusskola',
  'Baldones vidusskola',
  'Baložu vidusskola',
  'Privātsākumskola "Varavīksne"',
  'Liepājas Ezerkrasta sākumskola',
  'Liepājas Centra sākumskola',
  'Liepājas Katoļu pamatskola',
  'Liepājas 3. pamatskola',
  'Liepājas 8. vidusskola',
  'J. Čakstes Liepājas pilsētas 10. vidusskola',
  'Oskara Kalpaka Liepājas 15. vidusskola',
  'Draudzīgā aicinājuma Liepājas pilsētas 5. vidusskola',
  'Liepājas Raiņa 6. vidusskola',
  'Liepājas 7. vidusskola',
  'Liepājas Liedaga vidusskola',
  '"Liepājas Līvupes pamatskola - attīstības centrs"',
  'Krišjāņa Valdemāra Ainažu pamatskola',
  'Lādezera pamatskola',
  'Pāles pamatskola',
  'Umurgas pamatskola',
  'Vidrižu pamatskola',
  'Baumaņu Kārļa Viļķenes pamatskola',
  'Liepupes pamatskola',
  'Staiceles pamatskola',
  'Alojas Ausekļa vidusskola',
  'Salacgrīvas vidusskola',
  'Limbažu vidusskola',
  'Limbažu Valsts ģimnāzija',
  'Limbažu novada speciālā pamatskola',
  'Jersikas pamatskola',
  'Jaunsilavas pamatskola',
  'Rožupes pamatskola',
  'Līvānu 1. vidusskola',
  'Rudzātu vidusskola',
  'Līvānu 2. vidusskola',
  'Rudzātu speciālā pamatskola',
  'Salnavas pamatskola',
  'Pušmucovas pamatskola',
  'Mērdzenes pamatskola',
  'Pildas pamatskola',
  'Ludzas Mūzikas pamatskola',
  'Mežvidu pamatskola',
  'Ciblas vidusskola',
  'Ludzas 2. vidusskola',
  'Zilupes vidusskola',
  'Kārsavas vidusskola',
  'Ludzas pilsētas vidusskola',
  'Kristiāna Dāvida pamatskola',
  'Vestienas pamatskola',
  'Praulienas pamatskola',
  'Liezēres pamatskola',
  'Lazdonas pamatskola',
  'Kusas pamatskola',
  'Kalsnavas pamatskola',
  'Dzelzavas pamatskola',
  'Degumnieku pamatskola',
  'Bērzaunes pamatskola',
  'Barkavas pamatskola',
  'Andreja Eglīša Ļaudonas vidusskola',
  'Lubānas vidusskola',
  'Ērgļu vidusskola',
  'Cesvaines vidusskola',
  'Madonas pilsētas vidusskola',
  'Dzelzavas speciālā pamatskola',
  'Privātā Montessori sākumskola',
  'Mārupes novada Skultes sākumskola',
  'Babītes novada pašvaldības Salas sākumskola',
  'Pamatskola "Brīvā Māras skola"',
  'Mārupes pamatskola',
  'Jaunmārupes pamatskola',
  'Latvijas Starptautiskā skola',
  'Starptautiskā vidusskola "Ekziperī" SIA',
  'King"s College Latvia SIA',
  'Babītes vidusskola',
  'Mārupes Valsts ģimnāzija',
  'Ogres sākumskola',
  'Pamatskola "Ogres Baltā skola"',
  'Ogres 1. vidusskola',
  'Jaunogres vidusskola',
  'Privātā sākumskola "Ķiparu nams"',
  'Ikšķiles novada pašvaldības vispārējās izglītības iestāde Tīnūžu sākumskola',
  'Pamatskola "Ikšķiles Brīvā skola"',
  'Ogresgala pamatskola',
  'Ogres Valsts ģimnāzija',
  'Lielvārdes pamatskola',
  'Ķeipenes pamatskola',
  'Birzgales pamatskola',
  'Lēdmanes pamatskola',
  'Taurupes pamatskola',
  'Jumpravas pamatskola',
  'Suntažu vidusskola',
  'Madlienas vidusskola',
  'Ikšķiles vidusskola',
  'Ķeguma komercnovirziena vidusskola',
  'Edgara Kauliņa Lielvārdes vidusskola',
  'Valdemāra pamatskola',
  'Olaines 1. vidusskola',
  'Olaines 2. vidusskola',
  'Pamatskola "Preiļu Brīvā skola"',
  'Preiļu 1. pamatskola',
  'Galēnu pamatskola',
  'Pelēču pamatskola',
  'Salas pamatskola',
  'Rušonas pamatskola',
  'Aglonas Katoļu ģimnāzija',
  'Vārkavas vidusskola',
  'Riebiņu vidusskola',
  'Aglonas vidusskola',
  'Preiļu 2. vidusskola',
  'Rēzeknes sākumskola',
  'Rēzeknes 6. pamatskola',
  'Rēzeknes Katoļu vidusskola',
  'Austrumlatvijas Tehnoloģiju vidusskola',
  'Rēzeknes 2. vidusskola',
  'Rēzeknes 3. vidusskola',
  'Rēzeknes 5. vidusskola',
  'Rēzeknes 4. vidusskola',
  'Rēzeknes valsts poļu ģimnāzija',
  'Rēzeknes pamatskola-attīstības centrs',
  'Feimaņu pamatskola',
  'Rēznas pamatskola',
  'Dekšāru pamatskola',
  'Sakstagala Jāņa Klīdzēja pamatskola',
  'Verēmu pamatskola',
  'Gaigalavas pamatskola',
  'Viļānu vidusskola',
  'Kaunatas vidusskola',
  'Lūcijas Rancānes Makašānu Amatu vidusskola',
  'Tiskādu vidusskola',
  'Dricānu vidusskola',
  'Nautrēnu vidusskola',
  'Maltas vidusskola',
  'Rēzeknes novada speciālā pamatskola',
  'Profesionālās izglītības kompetences centrs "Nacionālā Mākslu vidusskola"',
  'Kristīgā sākumskola "Ceļš"',
  'Rīgas Valda Zālīša sākumskola',
  'Rīgas sākumskola "Valodiņa"',
  'Rīgas Centra pamatskola',
  'Privātā pamatskola DOMDARIS',
  'Rīgas Centra daiļamatniecības pamatskola',
  'Pamatskola "Rīdze"',
  'Privātā Rīgas Tehnolingvistiskā ģimnāzija',
  'Privātā vidusskola "INNOVA"',
  'Rīgas Centra humanitārā vidusskola',
  'Rīgas 40. vidusskola',
  'Rīgas 22. vidusskola',
  'Rīgas Natālijas Draudziņas vidusskola ',
  'Rīgas Raiņa vidusskola',
  'Rīgas 1. pamatskola-attīstības centrs',
  'Āgenskalna sākumskola',
  'Rīgas Valdorfskola',
  'Rīgas Bolderājas Jaunā pamatskola',
  'Rīgas Baltmuižas pamatskola',
  'Rīgas 69. pamatskola',
  'Privātskola "Latreia"',
  'Privātā vidusskola "Templum"',
  'Privātā vidusskola "Patnis"',
  'Rīgas 34. vidusskola',
  'Rīgas Daugavgrīvas vidusskola',
  'Rīgas Anniņmuižas vidusskola',
  'Rīgas 41. vidusskola',
  'Iļģuciema vidusskola',
  'Rīgas 71. vidusskola',
  'Rīgas Imantas vidusskola',
  'Rīgas Ostvalda vidusskola',
  'Rīgas 96. vidusskola',
  'Rīgas 33. vidusskola',
  'Tālmācības vidusskola "Rīgas 1. vidusskola"',
  'Sākumskola "Lielie stāsti"',
  'Privātā pamatskola "Maksima"',
  'Privātā pamatskola "RIMS"',
  'Jankas Kupalas Rīgas Baltkrievu pamatskola',
  'O.Kalpaka Rīgas Tautas daiļamatu pamatskola',
  'Andreja Pumpura Rīgas 11. pamatskola',
  'Rīgas Pļavnieku pamatskola',
  'Rīgas 1. Kristīgā pamatskola',
  'Rīgas Valda Avotiņa pamatskola',
  'Rīgas 65. pamatskola',
  'Rīgas Austrumu pamatskola',
  'Rīgas Sergeja Žoltoka pamatskola',
  'Informācijas sistēmu menedžmenta augstskolas vidusskola "PREMJERS"',
  'Privātā vidusskola "Norma"',
  'Habad Ebreju privātā vidusskola',
  'Torņakalna Privātā vidusskola',
  'Rīgas 93. vidusskola',
  'Rīgas 72. vidusskola',
  'Rīgas 88. vidusskola',
  'Rīgas 15. vidusskola',
  'Rīgas 25. vidusskola',
  'Rīgas 92. vidusskola',
  'Rīgas Ķengaraga vidusskola',
  'Rīgas 75. vidusskola',
  'Rīgas 86. vidusskola',
  'Rīgas 6. vidusskola',
  'Rīgas 51. vidusskola',
  'Rīgas Ukraiņu vidusskola',
  'Rīgas Lietuviešu vidusskola',
  'J.G.Herdera Rīgas Grīziņkalna vidusskola',
  'Rīgas Reinholda Šmēlinga vidusskola',
  'Rīgas 1. vidusskola',
  'Rīgas Daugavas pamatskola',
  'Rīgas 2. pamatskola',
  'Rīgas 5. pamatskola-attīstības centrs',
  'Privātā sākumskola "Namiņš"',
  'Privātā Montesori sākumskola "Pētnieki"',
  'Rīgas Kristīgā pamatskola',
  'Privātā vispārējā izglītības iestāde "Mīlestības Māja"',
  'Pamatskola BŪSIM',
  'Mežciema pamatskola',
  'Privātā vidusskola "Klasika"',
  'Privātā vidusskola "Laisma"',
  'Privātā vidusskola "MAXVEL"',
  'Privātā vidusskola "Citruss"',
  'SIA "Rīgas Vācu Skola"',
  'Rīgas 84. vidusskola',
  'Rīgas 85. vidusskola',
  'Rīgas Franču licejs',
  'Rīgas Hanzas vidusskola',
  'Rīgas 45. vidusskola',
  'Rīgas Teikas vidusskola',
  'Rīgas Purvciema vidusskola',
  'Rīgas 21. vidusskola',
  'Rīgas 49. vidusskola',
  'Rīgas 89. vidusskola',
  'Rīgas 80. vidusskola',
  'Š. Dubnova Rīgas Ebreju vidusskola',
  'Rīgas 63. vidusskola',
  'Rīgas 64. vidusskola',
  'Rīgas Juglas vidusskola',
  'Rīgas 74. vidusskola',
  'Rīgas Itas Kozakēvičas Poļu vidusskola',
  'Rīgas 9. vidusskola',
  'Tālmācības vidusskola "Rīgas Komercskola"',
  'Rīgas Valsts klasiskā ģimnāzija',
  'Rīgas 4. pamatskola',
  'Rīgas Strazdumuižas vidusskola-attīstības centrs',
  'Sākumskola "Montessori māja"',
  'Pārdaugavas Montesori sākumskola',
  'Pārdaugavas Valdorfa pamatskola',
  'Rīgas Pārdaugavas pamatskola',
  'Friča Brīvzemnieka pamatskola',
  'Rīgas Igauņu pamatskola',
  'Rīgas vispārizglītojošā privātā vidusskola "Evrika"',
  'Rīgas Katoļu ģimnāzija',
  'Rīgas Starptautiskā skola',
  '"Brīvdabas vidusskola"',
  'Rīgas Angļu ģimnāzija',
  'Ziemeļvalstu ģimnāzija',
  'Rīgas Zolitūdes ģimnāzija',
  'Rīgas 95. vidusskola',
  'Rīgas 47. vidusskola',
  'Rīgas Ziepniekkalna vidusskola',
  'Rīgas 14. vidusskola',
  'Rīgas Arkādijas vidusskola',
  'Rīgas 3. pamatskola',
  'Rīgas Ēbelmuižas pamatskola',
  'Rīgas Montessori sākumskola',
  'ŽILA VERNA RĪGAS FRANČU SKOLA',
  'Rīgas 7. pamatskola',
  'Rīgas Čiekurkalna pamatskola',
  'Rīgas 13. vidusskola',
  'Rīgas Rīnūžu vidusskola',
  'Puškina licejs',
  'Rīgas 28. vidusskola',
  'Rīgas 10. vidusskola',
  'Rīgas 31. vidusskola',
  'Rīgas 46. vidusskola',
  'Rīgas Kultūru vidusskola',
  'Rīgas 18. vidusskola',
  'Rīgas 66. vidusskola',
  'Upesleju sākumskola',
  'Stopiņu pamatskola',
  'Berģu Mūzikas un mākslas pamatskola',
  'Garkalnes Mākslu un vispārizglītojošā pamatskola',
  'Vangažu vidusskola',
  'Ulbrokas vidusskola',
  'Ropažu vidusskola',
  'Gaismas pamatskola',
  'Salaspils pamatskola',
  'Salaspils novada pašvaldības iestāde "Salaspils 1. vidusskola"',
  'Saldus novada pašvaldības Striķu sākumskola',
  'Saldus novada pašvaldības Jaunlutriņu sākumskola',
  'Saldus novada pašvaldības Pampāļu sākumskola',
  'Saldus novada pašvaldības Lutriņu pamatskola',
  'Remtes pamatskola',
  'Saldus pamatskola',
  'Gaiķu pamatskola',
  'Blīdenes pamatskola',
  'Saldus novada pašvaldības Rubas pamatskola',
  'Saldus novada pašvaldības Zirņu pamatskola',
  'Cieceres pamatskola',
  'Saldus novada pašvaldības Ezeres pamatskola',
  'Saldus novada pašvaldības Druvas vidusskola',
  'Nīgrandes pagasta Kalnu vidusskola',
  'Brocēnu vidusskola',
  'Saldus vidusskola',
  'Kalnsētas pamatskola',
  'Sējas pamatskola',
  'Saulkrastu novada vidusskola',
  'Laurenču sākumskola',
  'Garlība Merķeļa Lēdurgas pamatskola',
  'Mores pamatskola',
  'Siguldas 1. pamatskola',
  'Allažu pamatskola',
  'Inčukalna pamatskola',
  'Krimuldas vidusskola',
  'Siguldas pilsētas vidusskola',
  'Mālpils vidusskola',
  'Launkalnes sākumskola',
  'Trapenes sākumskola',
  'Biedrības "Tautskola 99 Baltie zirgi" pamatskola',
  'Blomes pamatskola',
  'Bilskas pamatskola',
  'Palsmanes pamatskola',
  'Variņu pamatskola',
  'Grundzāles pamatskola',
  'Ojāra Vācieša Gaujienas pamatskola',
  'Dāvja Ozoliņa Apes pamatskola',
  'Raunas pamatskola',
  'Smiltenes vidusskola',
  'Smiltenes novada speciālā pamatskola',
  'Gaujienas pamatskola',
  'Lībagu sākumskola',
  'Virbu sākumskola',
  'Talsu pamatskola',
  'Laucienes pamatskola',
  'Pastendes pamatskola',
  'Stendes pamatskola',
  'Pūņu pamatskola',
  'Kolkas pamatskola',
  'Sabiles pamatskola',
  'Talsu Kristīgā vidusskola',
  'Valdemārpils vidusskola',
  'Talsu 2. vidusskola',
  'Rojas vidusskola',
  'Dundagas vidusskola',
  'Mērsraga vidusskola',
  'Talsu novada vidusskola',
  'Talsu Valsts ģimnāzija',
  'Upesgrīvas pamatskola',
  'Sēmes sākumskola',
  'Milzkalnes sākumskola',
  'Zemītes sākumskola',
  'Lapmežciema pamatskola',
  'Smārdes pamatskola',
  'Pūres pamatskola',
  'Cēres pamatskola',
  'Tukuma E. Birznieka-Upīša 1. pamatskola',
  'Džūkstes pamatskola',
  'Tukuma 3. pamatskola',
  'Zantes pamatskola',
  'Vānes pamatskola',
  'Irlavas pamatskola',
  'Tumes pamatskola',
  'Kandavas Reģionālā pamatskola',
  'Kandavas Kārļa Mīlenbaha vidusskola',
  'Jaunpils vidusskola',
  'Tukuma 2. vidusskola',
  'Engures vidusskola',
  'Tukuma Raiņa Valsts ģimnāzija',
  'Tukuma novada speciālās izglītības iestāde',
  'Ērģemes pamatskola',
  'Valkas Jāņa Cimzes ģimnāzija',
  'Valmieras Pārgaujas sākumskola',
  'Valmieras sākumskola',
  'Privātā pamatskola ZAĻĀ SKOLA',
  'Privātā pamatskola VALMIERAS ZAĻĀ SKOLA',
  'Universum mūzikas un mākslas vidusskola',
  'Valmieras Viestura vidusskola',
  'Valmieras 2. vidusskola',
  'Valmieras 5. vidusskola',
  'Valmieras Gaujas krasta vidusskola - attīstības centrs',
  'Brenguļu sākumskola',
  'Matīšu pamatskola',
  'Rubenes pamatskola',
  'J. Endzelīna Kauguru pamatskola',
  'Rencēnu pamatskola',
  'Kocēnu pamatskola',
  'Jura Neikena Dikļu pamatskola',
  'Trikātas pamatskola',
  'Burtnieku Ausekļa pamatskola',
  'Strenču pamatskola',
  'Naukšēnu vidusskola',
  'Rūjienas vidusskola',
  'Mazsalacas vidusskola',
  'Sociālās korekcijas izglītības iestāde "Naukšēni"',
  'Ziemeļvidzemes pamatskola',
  'Varakļānu vidusskola',
  'Ventspils Centra sākumskola',
  'Ventspils 1. pamatskola',
  'Ventspils 2. pamatskola',
  'Ventspils Pārventas pamatskola',
  'Ventspils 3. pamatskola',
  'Ventspils 2. vidusskola',
  'Ventspils 4. vidusskola',
  'Ventspils 6. vidusskola',
  'Zūru pamatskola',
  'Užavas pamatskola',
  'Popes pamatskola',
  'Tārgales pamatskola',
  'Ances pamatskola',
  'Puzes pamatskola',
  'Piltenes pamatskola',
  'Ugāles vidusskola',
  'Annahites pamatskola',
  'Jēkabpils Agrobiznesa koledža',
  'Liepājas Jūrniecības koledža',
  'Profesionālās izglītības kompetences centrs "Rīgas Tehniskā koledža"',
  'Rīgas Celtniecības koledža',
  'Aizkraukles Profesionālā vidusskola',
  'Daugavpils Tehnoloģiju un tūrisma tehnikums',
  'Jelgavas Tehnikums',
  'Kandavas Lauksaimniecības tehnikums',
  'Kuldīgas Tehnoloģiju un tūrisma tehnikums',
  'Latgales industriālais tehnikums',
  'Ogres tehnikums',
  'Profesionālās izglītības kompetences centrs "Liepājas Valsts tehnikums"',
  'Profesionālās izglītības kompetences centrs "Rīgas Valsts tehnikums"',
  'Rēzeknes tehnikums',
  'Rīgas 3. arodskola',
  'Rīgas Mākslas un mediju tehnikums',
  'Rīgas Stila un modes tehnikums',
  'Rīgas Tirdzniecības profesionālā vidusskola',
  'Saldus tehnikums',
  'Smiltenes tehnikums',
  'Valmieras tehnikums',
  'Valsts sabiedrība ar ierobežotu atbildību "Rīgas Tūrisma un radošās industrijas tehnikums"',
  'Ventspils Tehnikums',
  'Vidzemes Tehnoloģiju un dizaina tehnikums',
];
