import React from 'react';
import PropTypes from 'prop-types';
import styles from './FinalMsg.module.scss';

export const FinalMsgHeading = ({ heading }) => (
  <h1 className={styles.heading}>{heading}</h1>
);

FinalMsgHeading.propTypes = {
  heading: PropTypes.string,
};

FinalMsgHeading.defaultProps = {
  heading: '',
};
