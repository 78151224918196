import TeamService from 'src/services/teamService';
import {
  AUTH_SET_INITIALIZED,
  AUTH_SET_TEAM,
  AUTH_SIGNIN_TEAM,
  AUTH_SIGNUP_TEAM,
  AUTH_SIGNOUT_TEAM,
  AUTH_SET_ERRORS,
  AUTH_RESET_ERRORS,
} from './actionTypes';

export const authGetTeam = () => (dispatch) =>
  TeamService.getTeam()
    .then((res) =>
      dispatch({
        type: AUTH_SET_TEAM,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    )
    .finally(() =>
      dispatch({
        type: AUTH_SET_INITIALIZED,
      }),
    );

export const signupTeam = (data) => (dispatch) =>
  TeamService.signup(data)
    .then((res) =>
      dispatch({
        type: AUTH_SIGNUP_TEAM,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );

export const signinTeam = (data) => (dispatch) =>
  TeamService.signin(data)
    .then((res) =>
      dispatch({
        type: AUTH_SIGNIN_TEAM,
        payload: res.data,
      }),
    )
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    );

export const signoutTeam = () => (dispatch) =>
  TeamService.signout()
    .catch((err) =>
      dispatch({
        type: AUTH_SET_ERRORS,
        payload: err.response,
      }),
    )
    .finally(() => {
      dispatch({
        type: AUTH_SIGNOUT_TEAM,
      });
    });

export const resetAuthErrors = () => (dispatch) =>
  dispatch({
    type: AUTH_RESET_ERRORS,
  });
