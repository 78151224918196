import { useEffect } from 'react';
import { authGetTeam } from 'src/store/actions/authActions';
import { useDispatch } from 'react-redux';

const AppAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authGetTeam());
  }, []);

  return null;
};

export default AppAuth;
