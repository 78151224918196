import React from 'react';
import { Provider } from 'react-redux';
import store from 'src/store/store';
import AppAuth from 'src/components/AppAuth';
import Pages from 'src/pages';
import 'src/assets/css/styles.scss';

const App = () => (
  <Provider store={store}>
    <AppAuth />
    <Pages />
  </Provider>
);

export default App;
