import React from 'react';
import PropTypes from 'prop-types';
import styles from './SignupForm.module.scss';

export const SignupFormHeading = ({ heading }) => (
  <h1 className={styles.heading}>{heading}</h1>
);

SignupFormHeading.propTypes = {
  heading: PropTypes.string,
};

SignupFormHeading.defaultProps = {
  heading: '',
};
