import ShareModal from 'src/modals/ShareModal';

export const getModal = (type) => {
  switch (type) {
    case 'SHARE_MODAL':
      return ShareModal;

    default:
      return null;
  }
};
