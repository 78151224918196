import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './StockTableInput.module.scss';

const StockTableInput = ({
  id,
  name,
  defaultValue,
  onChange,
  hasError,
  ...inputProps
}) => (
  <div className={styles.wrapper}>
    <input
      type="text"
      id={id}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      className={cx(styles.input, hasError && styles.hasError)}
      {...inputProps}
    />
  </div>
);

export default StockTableInput;

StockTableInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
};

StockTableInput.defaultProps = {
  id: '',
  name: '',
  defaultValue: undefined,
  onChange: null,
  hasError: false,
};
