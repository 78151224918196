import React from 'react';
import PropTypes from 'prop-types';
import Topbar from 'src/uikit/Topbar';
import CookieModal from 'src/uikit/CookieModal';
import Modals from 'src/components/Modals';
import styles from './MainLayout.module.scss';

const MainLayout = ({ children, hero }) => (
  <div className={styles.wrapper}>
    <Topbar />
    <main>
      {hero}
      {children}
    </main>
    <CookieModal />
    <Modals />
  </div>
);

export default MainLayout;

MainLayout.propTypes = {
  hero: PropTypes.node,
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  hero: null,
  children: null,
};
