import React, { useEffect, useState } from 'react';
import { Button } from 'src/uikit/Button';
import { useCookies } from 'react-cookie';
import Modal from 'src/uikit/Modal';
import styles from './CookieModal.module.scss';

const CookieModal = () => {
  const [show, setShow] = useState(false);
  const [cookies, setCookie] = useCookies(['_ck']);

  const handleClose = () => {
    setShow(false);

    setCookie('_ck', true, { maxAge: 86400 * 365 });
  };

  useEffect(() => {
    if ('_ck' in cookies) return;

    setShow(true);
  }, []);

  if (!show) return null;

  return (
    <Modal>
      <h3 className={styles.heading}>Mūsu sīkdatnes</h3>

      <p className={styles.mainText}>
        Šajā tīmekļa vietnē Swedbank izmanto tikai nepieciešamās sīkdatnes, lai
        nodrošinātu šis vietnes darbību.
      </p>

      <p className={styles.footnote}>
        Nepieciešamās sīkdatnes tiek apstrādātas, ņemot vērā mūsu leģitīmās
        intereses, tāpēc jūsu piekrišana netiek prasīta. Šajā tīmekļa vietnē
        netiek izmantotas trešo pušu sīkdatnes. Plašākai informācijai skatiet
        mūsu{' '}
        <a
          href={`${process.env.REACT_APP_BASE}/sikdatnu_izmantosanas_politika_ieguldijumu_portfelis.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          sīkdatņu politiku
        </a>
        .
      </p>

      <div className={styles.footer}>
        <Button
          type="button"
          variant="primary"
          label="Aizvērt"
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
};

export default CookieModal;
