import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './ChartLayout.module.scss';

export const ChartLayoutSection = ({ children, size }) => (
  <div className={cx(styles.section, styles[size])}>{children}</div>
);

ChartLayoutSection.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

ChartLayoutSection.defaultProps = {
  children: null,
  size: 'lg',
};
