import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeroImg.module.scss';

const HeroImg = ({ src }) => (
  <div className={styles.wrapper}>
    <div className={styles.hero} style={{ backgroundImage: `url(${src})` }} />
  </div>
);

export default HeroImg;

HeroImg.propTypes = {
  src: PropTypes.string,
};

HeroImg.defaultProps = {
  src: '',
};
