import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uiShowModal } from 'src/store/actions/uiActions';
import { signoutTeam } from 'src/store/actions/authActions';
import Layout from 'src/layouts/MainLayout';
import {
  FinalMsgWrapper,
  FinalMsgHeading,
  FinalMsgText,
  FinalMsgFooter,
} from 'src/uikit/FinalMsg';
import { Button } from 'src/uikit/Button';

const FinalPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignout = () => {
    dispatch(signoutTeam());
    navigate('/');
  };

  const handleShareModal = () =>
    dispatch(
      uiShowModal({
        type: 'SHARE_MODAL',
      }),
    );

  return (
    <Layout>
      <div className="container">
        <FinalMsgWrapper>
          <FinalMsgHeading heading="Paldies, portfelis saglabāts." />

          <FinalMsgText>
            Atceries atgriezties pēc mēneša, lai apskatītu, kā mainījies
            portfeļa stāvoklis.
          </FinalMsgText>

          <FinalMsgFooter>
            <Button
              type="button"
              label="Uz sākumu"
              variant="primary"
              onClick={handleSignout}
            />

            <Button
              variant="secondary"
              label="Kopīgot"
              onClick={handleShareModal}
            />
          </FinalMsgFooter>
        </FinalMsgWrapper>
      </div>
    </Layout>
  );
};

export default FinalPage;
