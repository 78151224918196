import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToolLayout.module.scss';

export const ToolFooter = ({ children }) => (
  <div className={styles.footer}>{children}</div>
);

ToolFooter.propTypes = {
  children: PropTypes.node,
};

ToolFooter.defaultProps = {
  children: null,
};
