import { UI_SHOW_MODAL, UI_CLOSE_MODAL } from 'src/store/actions/actionTypes';

const initialState = {
  modal: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_SHOW_MODAL:
      return {
        ...state,
        modal: action.payload,
      };

    case UI_CLOSE_MODAL:
      return {
        ...state,
        modal: null,
      };

    default:
      return state;
  }
};

export default reducer;
