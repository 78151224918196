import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import StockTable from 'src/uikit/StockTable';
import styles from './StockShareChart.module.scss';

const colors = [
  '#FB4F00',
  '#FDC92A',
  '#990066',
  '#D28AC5',
  '#31A3AE',
  '#8ACDC3',
  '#FF9100',
  '#C5569A',
  '#FFECFF',
  '#FFF1CD',
];

const StockShareChart = ({ portfolio }) => (
  <div className={styles.wrapper}>
    <div className={styles.legend}>
      <div className={styles.legendInner}>
        <StockTable>
          <colgroup>
            <col width="154" />
            <col width="184" />
          </colgroup>

          <thead>
            <tr>
              <th />
              <th>Īpatsvars portfelī, %</th>
            </tr>
          </thead>

          <tbody>
            {portfolio.map((stock, i) => (
              <tr key={stock.title}>
                <td>
                  <div className={styles.colorWrapper}>
                    <span
                      className={styles.color}
                      style={{ backgroundColor: colors[i] }}
                    />
                    <span className="textMedium">{stock.title}</span>
                  </div>
                </td>
                <td data-label="Īpatsvars portfelī, %">{stock.share}</td>
              </tr>
            ))}
          </tbody>
        </StockTable>
      </div>
    </div>

    <div className={styles.chartWrapper}>
      <div className={styles.chartTitle}>Akciju sadalījums</div>

      <Chart
        chartType="PieChart"
        data={[
          ['', ''],
          ...portfolio.reduce((acc, item) => {
            acc.push([item.title, parseInt(item.share, 10)]);
            return acc;
          }, []),
        ]}
        options={{
          legend: 'none',
          slices: [...portfolio.map((share, i) => ({ color: colors[i] }))],
          titleTextStyle: {
            fontName: 'Roboto',
            fontSize: 14,
            color: '#512B2B',
            bold: true,
          },
          pieSliceTextStyle: {
            fontName: 'SwedbankHeadline-Black',
            fontSize: 20,
          },
          chartArea: {
            width: '100%',
            height: '100%',
            top: '5%',
            bottom: '5%',
          },
        }}
        width="100%"
        height="250px"
        legendToggle
      />
    </div>
  </div>
);

export default StockShareChart;

StockShareChart.propTypes = {
  portfolio: PropTypes.arrayOf(PropTypes.object),
};

StockShareChart.defaultProps = {
  portfolio: [],
};
