import {
  AUTH_SET_INITIALIZED,
  AUTH_SET_TEAM,
  AUTH_SIGNIN_TEAM,
  AUTH_SIGNUP_TEAM,
  AUTH_SIGNOUT_TEAM,
  AUTH_SET_ERRORS,
  AUTH_RESET_ERRORS,
  TEAM_UPDATE_PORTFOLIO,
  TEAM_SET_ERRORS,
  TEAM_RESET_ERRORS,
} from 'src/store/actions/actionTypes';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  team: null,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_INITIALIZED:
      return {
        ...state,
        isInitialized: true,
      };

    case AUTH_SET_TEAM:
      return {
        ...state,
        isAuthenticated: true,
        team: action.payload,
        errors: null,
      };

    case AUTH_SIGNIN_TEAM:
    case AUTH_SIGNUP_TEAM:
      return {
        ...state,
        isAuthenticated: true,
        team: action.payload,
        errors: null,
      };

    case AUTH_SIGNOUT_TEAM:
      return {
        ...initialState,
        isInitialized: true,
      };

    case TEAM_UPDATE_PORTFOLIO:
      return {
        ...state,
        team: action.payload,
        errors: null,
      };

    case AUTH_SET_ERRORS:
    case TEAM_SET_ERRORS:
      return {
        ...state,
        errors: {
          status: action.payload?.status || '',
          message: action.payload?.data?.message,
          errors: action.payload?.data?.errors,
        },
      };

    case AUTH_RESET_ERRORS:
    case TEAM_RESET_ERRORS:
      return {
        ...state,
        errors: null,
      };

    default:
      return state;
  }
};

export default reducer;
