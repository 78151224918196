import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { signoutTeam } from 'src/store/actions/authActions';
import { updatePrices } from 'src/store/actions/teamActions';
import { uiShowModal } from 'src/store/actions/uiActions';
import useForm from 'src/hooks/useForm';
import Layout, {
  ToolHeading,
  ToolIntro,
  ToolWarning,
  ToolFooter,
} from 'src/layouts/ToolLayout';
import { Button, ButtonLink } from 'src/uikit/Button';
import ButtonGroup from 'src/uikit/ButtonGroup';
import StockTable from 'src/uikit/StockTable';
import StockTableInput from 'src/uikit/StockTableInput';
import Alert from 'src/uikit/Alert';

const formatRoiValue = (val, percent) => {
  if (!val || val === '' || Number.isNaN(val)) return '';

  if (val > -0.09 && val < 0)
    return percent ? `${parseFloat(0).toFixed(1)}%` : parseFloat(0).toFixed(1);

  return percent
    ? `${parseFloat(val).toFixed(1)}%`
    : parseFloat(val).toFixed(1);
};

const UpdatePricesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { team, errors } = useSelector((state) => state.teamReducer);
  const { values, setValues } = useForm({
    shares: [],
  });
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalRoi, setTotalRoi] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setInputErrors(null);

    const formData = JSON.stringify(values);

    dispatch(updatePrices(formData, () => setIsSubmitted(true)));
  };

  const getStockRoi = (stock) => {
    let value = '';

    const stockValue =
      values.shares.find((item) => item.title === stock.title) || {};

    value =
      stockValue.new_price !== undefined &&
      !Number.isNaN(parseInt(stockValue.new_price, 10)) &&
      stock.price > 0
        ? (parseFloat(stockValue.new_price) / parseFloat(stock.price) - 1) * 100
        : '';

    return value !== '' ? parseFloat(value).toFixed(1) : '';
  };

  const handleChangeShare = (title, val) => {
    const nextShares = [...values.shares];

    const share = nextShares.find((item) => item.title === title);

    share['new_price'] = Number.isNaN(parseFloat(val))
      ? ''
      : parseFloat(val.replace(/,/g, '.'));

    setValues({ shares: nextShares });
  };

  const handleSignout = () => {
    dispatch(signoutTeam());
    navigate('/');
  };

  const handleShareModal = () =>
    dispatch(
      uiShowModal({
        type: 'SHARE_MODAL',
      }),
    );

  useEffect(() => {
    let nextTotalRoi = null;

    if (
      values.shares.length > 0 &&
      values.shares.filter(
        (item) =>
          item.price &&
          !Number.isNaN(item.price) &&
          item.new_price &&
          !Number.isNaN(item.new_price),
      ).length === values.shares.length
    ) {
      nextTotalRoi = values.shares.reduce((acc, item) => {
        const stockRoi =
          (parseFloat(item.new_price) / parseFloat(item.price) - 1) *
          parseFloat(item.share);

        acc += parseFloat(stockRoi);

        return acc;
      }, 0);
    }

    setTotalRoi(nextTotalRoi);
  }, [values]);

  useEffect(() => {
    setIsSubmitting(false);
    setInputErrors(errors?.errors);
  }, [errors]);

  useEffect(() => {
    if (!team || !team.id) return;

    setValues({ shares: team.portfolio });
  }, [team]);

  return (
    <Layout>
      <form onSubmit={handleSubmit} noValidate>
        <ToolHeading heading="Aktualizējiet cenas savām izvēlētajām uzņēmumu akcijām!" />

        <ToolIntro>
          <p>
            Cenas atrodamas biržas mājas lapā:{' '}
            <a
              href="https://nasdaqbaltic.com/statistics/lv/shares"
              target="_blank"
              rel="noreferrer"
            >
              https://nasdaqbaltic.com/statistics/lv/shares
            </a>
          </p>
        </ToolIntro>

        <StockTable>
          <colgroup>
            <col width="154" />
            <col width="184" />
            <col width="184" />
            <col width="184" />
            <col width="184" />
          </colgroup>

          <thead>
            <tr>
              <th />
              <th>Īpatsvars portfelī, %</th>
              <th>
                Pēdējā cena uz
                <br />
                {moment(team.created_at).format('DD.MM.YYYY')}, EUR
              </th>
              <th>
                Aktuālās cenas,
                <br />
                EUR
              </th>
              <th>Ienesīgums, %</th>
            </tr>
          </thead>

          <tbody>
            {team.portfolio.map((stock, i) => (
              <tr key={stock.title}>
                <td>{stock.title}</td>
                <td data-label="Īpatsvars portfelī, %">{stock.share}</td>
                <td
                  data-label={`Pēdējā cena uz ${moment(team.created_at).format(
                    'DD.MM.YYYY',
                  )}, EUR`}
                >
                  {stock.price}
                </td>
                <td data-label="Aktuālās cenas, EUR">
                  {isSubmitted ? (
                    stock.new_price
                  ) : (
                    <StockTableInput
                      defaultValue={stock.new_price || undefined}
                      onChange={(e) =>
                        handleChangeShare(stock.title, e.target.value)
                      }
                      hasError={
                        inputErrors && `shares.${i}.new_price` in inputErrors
                      }
                    />
                  )}
                </td>
                <td data-label="Ienesīgums, %">
                  {formatRoiValue(getStockRoi(stock), true)}
                </td>
              </tr>
            ))}
          </tbody>
        </StockTable>

        {inputErrors && !totalRoi && (
          <ToolWarning>
            <p>Lūdzu, ievadi visus datus!</p>
          </ToolWarning>
        )}

        {totalRoi !== null && (
          <ToolWarning>
            <p>
              Portfeļa kopējais ienesīgums{' '}
              <em>{formatRoiValue(totalRoi, true)}</em>
            </p>
          </ToolWarning>
        )}

        {totalRoi !== null && parseFloat(formatRoiValue(totalRoi)) < 0 && (
          <Alert variant="danger">
            Ienesīgums diemžēl ir negatīvs. Tomēr atcerieties, ka ieguldījumi ir
            ilgtermiņa stāsts un situācija var mainīties.
          </Alert>
        )}

        {totalRoi !== null && parseFloat(formatRoiValue(totalRoi)) > 0 && (
          <Alert variant="success">
            Ienesīgums ir pozitīvs, kas nozīmē, ka jūsu ieguldījumu portfelis ir
            kļuvis vērtīgāks.
          </Alert>
        )}

        {totalRoi !== null && parseFloat(formatRoiValue(totalRoi)) === 0 && (
          <Alert variant="info">
            Portfeļa vērtība nav pieaugusi, tomēr nav arī samazinājusies.
          </Alert>
        )}

        <ToolFooter>
          <ButtonGroup>
            {isSubmitted ? (
              <Button
                type="button"
                variant="primary"
                label="Uz sākumu"
                onClick={handleSignout}
              />
            ) : (
              <>
                <ButtonLink
                  to="/overview"
                  variant="secondary"
                  label="Atpakaļ"
                />
                <Button
                  type="submit"
                  variant="primary"
                  label="Apstiprināt"
                  isLoading={isSubmitting}
                />
              </>
            )}

            <Button
              variant="secondary"
              label="Kopīgot"
              onClick={handleShareModal}
            />
          </ButtonGroup>
        </ToolFooter>
      </form>
    </Layout>
  );
};

export default UpdatePricesPage;
