import React from 'react';
import PropTypes from 'prop-types';
import Topbar from 'src/uikit/Topbar';
import CookieModal from 'src/uikit/CookieModal';
import Modals from 'src/components/Modals';
import styles from './ToolLayout.module.scss';

export * from './ToolHeading';
export * from './ToolIntro';
export * from './ToolFootnote';
export * from './ToolWarning';
export * from './ToolFooter';

const ToolLayout = ({ children, noUser }) => (
  <div className={styles.wrapper}>
    <Topbar noUser={noUser} />
    <main>
      <div className="container">
        <div className={styles.inner}>{children}</div>
      </div>
    </main>
    <CookieModal />
    <Modals />
  </div>
);

export default ToolLayout;

ToolLayout.propTypes = {
  children: PropTypes.node,
  noUser: PropTypes.bool,
};

ToolLayout.defaultProps = {
  children: null,
  noUser: false,
};
