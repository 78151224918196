import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Alert.module.scss';

const Alert = ({ children, variant }) => (
  <div className={cx(styles.wrapper, styles[variant])}>{children}</div>
);

export default Alert;

Alert.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['success', 'info', 'danger']),
};

Alert.defaultProps = {
  children: null,
  variant: 'info',
};
