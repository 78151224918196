import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { signoutTeam } from 'src/store/actions/authActions';
import logo from 'src/assets/img/logo.svg';
import styles from './Topbar.module.scss';

const Topbar = ({ noUser }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { team, isAuthenticated } = useSelector((state) => state.teamReducer);

  const handleSignout = () => dispatch(signoutTeam());

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <img
            src={logo}
            alt="Swedbank"
            width="152"
            height="34"
            className={styles.logo}
          />
          <h1 className={styles.title}>IEGULDĪJUMU PORTFEĻA RĪKS</h1>

          {!noUser && (
            <>
              <div className={styles.mobileToggleContainer}>
                <button
                  type="button"
                  className={cx(
                    styles.mobileToggleBtn,
                    show && styles.isExpanded,
                  )}
                  onClick={() => setShow(!show)}
                >
                  Menu
                  <span />
                </button>
              </div>

              <div className={cx(styles.tools, show && styles.isExpanded)}>
                {isAuthenticated ? (
                  <>
                    <div className={styles.username}>{team.username}</div>

                    <span className={styles.sep} />

                    <button
                      type="button"
                      className={styles.signoutBtn}
                      onClick={handleSignout}
                    >
                      Iziet
                    </button>
                  </>
                ) : (
                  <>
                    <Link className={styles.signoutBtn} to="/signup">
                      Reģistrēties
                    </Link>

                    <span className={styles.sep} />

                    <Link className={styles.signoutBtn} to="/signin">
                      Ienākt
                    </Link>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;

Topbar.propTypes = {
  noUser: PropTypes.bool,
};

Topbar.defaultProps = {
  noUser: false,
};
