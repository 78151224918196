import React from 'react';
import PropTypes from 'prop-types';
import styles from './PreviewTable.module.scss';

const PreviewTable = ({ children }) => (
  <table className={styles.table}>{children}</table>
);

export default PreviewTable;

PreviewTable.propTypes = {
  children: PropTypes.node,
};

PreviewTable.defaultProps = {
  children: null,
};
