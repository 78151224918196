import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonGroup.module.scss';

const ButtonGroup = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

export default ButtonGroup;

ButtonGroup.propTypes = {
  children: PropTypes.node,
};

ButtonGroup.defaultProps = {
  children: null,
};
