import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import PrivateRoute from 'src/routing/PrivateRoute';
import AuthRoute from 'src/routing/AuthRoute';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { useScrollTop } from 'src/hooks/useScrollTop';

const Page = ({
  title,
  component: Component,
  isPrivate,
  isAuth,
  redirect,
  ...rest
}) => {
  usePageTitle(title);

  useScrollTop(title);

  if (redirect) return <Navigate to={redirect} replace />;

  if (isAuth) return <AuthRoute component={Component} {...rest} />;

  if (isPrivate) return <PrivateRoute component={Component} {...rest} />;

  return <Component {...rest} />;
};

export default Page;

Page.propTypes = {
  title: PropTypes.string,
  component: PropTypes.elementType,
  isPrivate: PropTypes.bool,
  isAuth: PropTypes.bool,
  redirect: PropTypes.string,
};

Page.defaultProps = {
  title: '',
  component: null,
  isPrivate: false,
  isAuth: false,
  redirect: '',
};
