import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getPreview } from 'src/store/actions/teamActions';
import Layout, {
  ToolHeading,
  ToolIntro,
  ToolWarning,
} from 'src/layouts/ToolLayout';
import StockTable from 'src/uikit/StockTable';
import Alert from 'src/uikit/Alert';

const formatRoiValue = (val, percent) => {
  if (!val || val === '' || Number.isNaN(val)) return '';

  if (val > -0.09 && val < 0)
    return percent ? `${parseFloat(0).toFixed(1)}%` : parseFloat(0).toFixed(1);

  return percent
    ? `${parseFloat(val).toFixed(1)}%`
    : parseFloat(val).toFixed(1);
};

const PreviewPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [team, setTeam] = useState({});
  const [totalRoi, setTotalRoi] = useState(null);

  const getStockRoi = (stock) => {
    let value = '';

    const stockValue =
      team.portfolio.find((item) => item.title === stock.title) || {};

    value =
      stockValue.new_price !== undefined &&
      !Number.isNaN(parseInt(stockValue.new_price, 10)) &&
      stock.price > 0
        ? (parseFloat(stockValue.new_price) / parseFloat(stock.price) - 1) * 100
        : '';

    return value !== '' ? parseFloat(value).toFixed(1) : '';
  };

  useEffect(() => {
    if (!id) return;

    dispatch(getPreview(id, setTeam));
  }, [id]);

  useEffect(() => {
    if (!team || !team.id) return;

    let nextTotalRoi = null;

    if (
      team.portfolio.length > 0 &&
      team.portfolio.filter(
        (item) =>
          item.price &&
          !Number.isNaN(item.price) &&
          item.new_price &&
          !Number.isNaN(item.new_price),
      ).length === team.portfolio.length
    ) {
      nextTotalRoi = team.portfolio.reduce((acc, item) => {
        const stockRoi =
          (parseFloat(item.new_price) / parseFloat(item.price) - 1) *
          parseFloat(item.share);

        acc += parseFloat(stockRoi);

        return acc;
      }, 0);
    }

    setTotalRoi(nextTotalRoi);
  }, [team]);

  if (!team || !team.id) return null;

  return (
    <Layout noUser>
      <ToolHeading heading="Portfeļa pārskats" />

      <ToolIntro>
        <p>
          Lietotājvārds: <span className="textBold">{team.username}</span>
        </p>
        <p>
          Skola: <span className="textBold">{team.school}</span>
        </p>
      </ToolIntro>

      <StockTable>
        <colgroup>
          <col width="154" />
          <col width="184" />
          <col width="184" />
          {totalRoi !== null && (
            <>
              <col width="184" />
              <col width="184" />
            </>
          )}
        </colgroup>

        <thead>
          <tr>
            <th />
            <th>Īpatsvars portfelī, %</th>
            <th>
              Cenas uz
              <br />
              {moment(team.created_at).format('DD.MM.YYYY')}, EUR
            </th>
            {totalRoi !== null && (
              <>
                <th>
                  Aktuālās cenas,
                  <br />
                  EUR
                </th>
                <th>Ienesīgums, %</th>
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {team.portfolio.map((stock) => (
            <tr key={stock.title}>
              <td>{stock.title}</td>
              <td data-label="Īpatsvars portfelī, %">{stock.share}</td>
              <td
                data-label={`Cenas uz ${moment(team.created_at).format(
                  'DD.MM.YYYY',
                )}, EUR`}
              >
                {stock.price}
              </td>
              {totalRoi !== null && (
                <>
                  <td data-label="Aktuālās cenas, EUR">{stock.new_price}</td>
                  <td data-label="Ienesīgums, %">
                    {formatRoiValue(getStockRoi(stock), true)}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </StockTable>

      {totalRoi !== null && (
        <ToolWarning>
          <p>
            Portfeļa kopējais ienesīgums{' '}
            <em>{formatRoiValue(totalRoi, true)}</em>
          </p>
        </ToolWarning>
      )}

      {totalRoi !== null && parseFloat(formatRoiValue(totalRoi)) < 0 && (
        <Alert variant="danger">
          Ienesīgums diemžēl ir negatīvs. Tomēr atcerieties, ka ieguldījumi ir
          ilgtermiņa stāsts un situācija var mainīties.
        </Alert>
      )}

      {totalRoi !== null && parseFloat(formatRoiValue(totalRoi)) > 0 && (
        <Alert variant="success">
          Ienesīgums ir pozitīvs, kas nozīmē, ka jūsu ieguldījumu portfelis ir
          kļuvis vērtīgāks.
        </Alert>
      )}

      {totalRoi !== null && parseFloat(formatRoiValue(totalRoi)) === 0 && (
        <Alert variant="info">
          Portfeļa vērtība nav pieaugusi, tomēr nav arī samazinājusies.
        </Alert>
      )}

      <ToolIntro>
        <h3 className="subHeading mb-24">Pamatojums</h3>

        <p className="dscr">{team.dscr}</p>
      </ToolIntro>
    </Layout>
  );
};

export default PreviewPage;
