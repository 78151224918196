import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Item from './Item';
import styles from './StockList.module.scss';

const StockList = ({ list, defaultValue, onChange }) => {
  const [selected, setSelected] = useState(defaultValue || []);

  const handleSelect = (stock, isChecked) => {
    let nextSelected = [...selected];

    if (isChecked && !nextSelected.some((item) => item.title === stock.title)) {
      nextSelected = [...nextSelected, stock];
    }

    if (!isChecked && nextSelected.some((item) => item.title === stock.title)) {
      nextSelected = nextSelected.filter((item) => item.title !== stock.title);
    }

    setSelected(nextSelected);

    onChange(nextSelected);
  };

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {list.map((item) => (
          <Item
            key={item.title}
            label={item.title}
            defaultSelected={defaultValue.some(
              (stock) => stock.title === item.title,
            )}
            onChange={(e) => handleSelect(item, e.target.checked)}
          />
        ))}
      </ul>
    </div>
  );
};

export default StockList;

StockList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

StockList.defaultProps = {
  list: [],
  defaultValue: [],
  onChange: null,
};
