import IndexPage from 'src/pages/IndexPage';
import SignupPage from 'src/pages/SignupPage';
import SigninPage from 'src/pages/SigninPage';
import StockSelectPage from 'src/pages/StockSelectPage';
import StockSharePage from 'src/pages/StockSharePage';
import StockPricePage from 'src/pages/StockPricePage';
import StockOverviewPage from 'src/pages/StockOverviewPage';
import FinalPage from 'src/pages/FinalPage';
import OverviewPage from 'src/pages/OverviewPage';
import UpdatePricesPage from 'src/pages/UpdatePricesPage';
import PreviewPage from 'src/pages/PreviewPage';
import ListPage from 'src/pages/ListPage';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Ieguldījumu portfeļa rīks - Swedbank',
    component: IndexPage,
    isPrivate: false,
  },
  {
    path: '/signup',
    title: 'Reģistrācija - Ieguldījumu portfeļa rīks - Swedbank',
    component: SignupPage,
    isAuth: true,
  },
  {
    path: '/signin',
    title: 'Ienākt - Ieguldījumu portfeļa rīks - Swedbank',
    component: SigninPage,
    isAuth: true,
  },
  {
    path: '/tool/1',
    title:
      'Izvēlies akcijas no saraksta savam portfelim - Ieguldījumu portfeļa rīks - Swedbank',
    component: StockSelectPage,
    isPrivate: true,
  },
  {
    path: '/tool/2',
    title:
      'Cik daudz no sava portfeļa izvietosiet šajās akcijās (procentos)? - Ieguldījumu portfeļa rīks - Swedbank',
    component: StockSharePage,
    isPrivate: true,
  },
  {
    path: '/tool/3',
    title:
      'Aktualizējiet cenas savām izvēlētajām uzņēmumu akcijām! - Ieguldījumu portfeļa rīks - Swedbank',
    component: StockPricePage,
    isPrivate: true,
  },
  {
    path: '/tool/4',
    title: 'Portfeļa pārskats - Ieguldījumu portfeļa rīks - Swedbank',
    component: StockOverviewPage,
    isPrivate: true,
  },
  {
    path: '/final',
    title:
      'Paldies, portfelis saglabāts - Ieguldījumu portfeļa rīks - Swedbank',
    component: FinalPage,
    isPrivate: true,
  },
  {
    path: '/overview',
    title: 'Portfeļa pārskats - Ieguldījumu portfeļa rīks - Swedbank',
    component: OverviewPage,
    isPrivate: true,
  },
  {
    path: '/update',
    title: 'Aktualizējiet cenas - Ieguldījumu portfeļa rīks - Swedbank',
    component: UpdatePricesPage,
    isPrivate: true,
  },
  {
    path: '/preview/:id',
    title: 'Portfeļa pārskats - Ieguldījumu portfeļa rīks - Swedbank',
    component: PreviewPage,
  },
  {
    path: '/list',
    title: 'Visi portfeļi - Ieguldījumu portfeļa rīks - Swedbank',
    component: ListPage,
  },
];

export default routes;
